import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import { useApiNotification, useNotification } from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import FormikPackagePlanSelect from "../../../components/inputs/FormikPackagePlanSelect";
import moment from "moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import * as Yup from "yup";

const CreateForm = props => {
  const { open, onClose, onEnroll, ...rest } = props;

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <FormikFormDialog {...rest} title={"Add Package Plan"} open={open} onClose={onClose} loading={false}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikPackagePlanSelect
              fullWidth
              name="packagePlan"
              label="Package Plan"
              margin="dense"
              variant="outlined"
              onChange={(event, value) => {
                setFieldValue("packagePlan", value, false);
                setFieldValue("effectiveDate", moment(), false);
                setFieldValue(
                  "expirationDate",
                  value
                    ? value.type === "PACKAGE"
                      ? moment().add(value.validityPeriod, "day")
                      : moment()
                          .add(value.validityPeriod, "month")
                          .subtract(1, "day")
                    : null,
                  false
                );

                setFieldTouched("packagePlan", true, false);
                setFieldTouched("effectiveDate", true, false);
                setFieldTouched("expirationDate", true, false);
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <FormikKeyboardDatePicker name="effectiveDate" label="Effective Date" />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardDatePicker disablePast name="expirationDate" label="Expiration Date" />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
};

export default function(props) {
  const { onOpen, onClose, onSuccess, parent, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Adding package plan "${values.packagePlan.name}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .post(`/api/customerUsers/${parent.id}/packagePlans`, createParams(values))
      .then(() => {
        notify(`Package plan "${values.packagePlan.name}" added`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to add package plans`,
              variant: "error"
            }
          },
          {
            message: `Unable to add package plan "${values.packagePlan.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validate={validate}>
      <CreateForm {...rest} parent={parent} onClose={onClose} />
    </Formik>
  );
}

const validationSchema = Yup.object().shape({
  packagePlan: Yup.object()
    .nullable(true)
    .required("Required"),
  effectiveDate: Yup.object()
    .nullable(true)
    .required("Required"),
  expirationDate: Yup.object()
    .nullable(true)
    .required("Required")
});

const validate = values => {
  const errors = {};

  if (values.effectiveDate && values.expirationDate) {
    if (values.expirationDate.isBefore(values.effectiveDate)) {
      errors.endDate = "Cannot be earlier than effective date";
    }
  }

  return errors;
};

const initialValues = {
  id: null,
  packagePlan: null,
  effectiveDate: null,
  expirationDate: null,
  packagePlanType: "",
  packagePlanName: "",
  remainingTicketCount: 0.0,
};

const createParams = values => {
  const params = new URLSearchParams();

  values.packagePlan && params.append("packagePlan", values.packagePlan.id);
  values.packagePlan && params.append("packagePlanName", values.packagePlan.name);
  values.packagePlan && params.append("packagePlanType", values.packagePlan.type);
  values.packagePlan && params.append("displayExpirationDate", values.packagePlan.displayExpirationDate);
  values.packagePlan &&
    values.packagePlan.items &&
    values.packagePlan.items
      .filter(item => item.type === "MAIN")
      .forEach(item => params.append("remainingTicketCount", item.ticketCount));
  values.effectiveDate && params.append("effectiveDate", values.effectiveDate.format("YYYY-MM-DD"));
  values.expirationDate && params.append("expirationDate", values.expirationDate.format("YYYY-MM-DD"));

  return params;
};
