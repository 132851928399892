import React from "react";
import { makeStyles } from "@material-ui/styles";
import StatusBullet from "./StatusBullet";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    alignItems: "center"
  },
  bullet: {
    marginRight: theme.spacing(1)
  }
}));

const LabelledStatusBullet = props => {
  const { label, ...rest } = props;

  const classes = useStyles();

  return (
    <span className={classes.root}>
      <StatusBullet {...rest} className={classes.bullet} />
      <Typography variant="inherit">{label}</Typography>
    </span>
  );
};

export default LabelledStatusBullet;
