import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { PACKAGE_PLAN_TYPE_OPTIONS, PACKAGE_PLAN_ITEM_TYPE_OPTIONS } from "../../../constants/app.constant";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikRadioGroup from "../../../components/inputs/FormikRadioGroup";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import { useFormikContext } from "formik";
import ItemCreateFormDialog from "./ItemCreateFormDialog";
import ItemEditFormDialog from "./ItemEditFormDialog";
import Button from "@material-ui/core/Button";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    ...theme.typography.h5
  }
}));

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext();

  const classes = useStyles();

  /*
  Dialog for create item
   */

  const [itemCreateFormDialogOpen, setItemCreateFormDialogOpen] = useState(false);

  const handleItemCreateFormDialogClose = useCallback(() => {
    setItemCreateFormDialogOpen(false);
  }, []);

  const itemCreateFormDialog = (
    <ItemCreateFormDialog open={itemCreateFormDialogOpen} onClose={handleItemCreateFormDialogClose} index={values.items.length} />
  );

  /*
  Dialog for edit item
   */

  const [itemEditFormDialogState, setItemEditFormDialogState] = useState({
    open: false
  });

  const handleItemEditFormDialogClose = useCallback(() => {
    setItemEditFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const itemEditFormDialog = <ItemEditFormDialog {...itemEditFormDialogState} onClose={handleItemEditFormDialogClose} />;

  /*
  Handlers
   */

  const handleItemCreate = () => {
    setItemCreateFormDialogOpen(true);
  };

  const handleItemEdit = item => {
    setItemEditFormDialogState(prevState => ({
      ...prevState,
      item: item,
      open: true
    }));
  };

  const handleItemRemove = pendingRemoveItem => {
    setFieldTouched("items", true, false);

    setFieldValue(
      "items",
      values.items.filter(item => item !== pendingRemoveItem)
    );
  };

  // function getSteps() {
  //   return ["Select master blaster campaign settings", "Create an ad group", "Create an ad"];
  // }

  // function getStepContent(stepIndex) {
  //   switch (stepIndex) {
  //     case 0:
  //       return "Select campaign settings...";
  //     case 1:
  //       return "What is an ad group anyways?";
  //     case 2:
  //       return "This is the bit I really care about!";
  //     default:
  //       return "Unknown stepIndex";
  //   }
  // }

  return (
    <FormikFormDialog {...rest} title={title} open={open} loading={loading} onClose={onClose}>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <FormikRadioGroup required name="type" label="Type" options={PACKAGE_PLAN_TYPE_OPTIONS} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <FormikTextField required fullWidth label="Name" name="name" variant="outlined" margin="dense" />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                required
                fullWidth
                label={values.type === "PACKAGE" ? "Validity Period (Days)" : "Validity Period (Months)"}
                name="validityPeriod"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikSwitch
            name="status"
            label="Toggle Package Plan Status"
            description="Indicate if this package plan is still used"
            FormLabelProps={{
              className: classes.sectionLabel
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikSwitch
            name="displayExpirationDate"
            label="Toggle Package Plan Display Expiration Date Or Not"
            description="Indicate if this package plan's expiration date will be shown to customers"
            FormLabelProps={{
              className: classes.sectionLabel
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {values.items && values.items.length > 0 && (
            <div>
              <strong className="py-2 my-2">Main Package Item</strong>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Store Branch</TableCell>
                    <TableCell>Offering Category</TableCell>
                    <TableCell>Ticket Count</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.items
                    .filter(item => {
                      return item.type === "MAIN";
                    })
                    .map(item => (
                      <TableRow hover key={item.offeringCategory.id}>
                        <TableCell>{item.applicableStoreBranch.name}</TableCell>
                        <TableCell>{item.offeringCategory.name}</TableCell>
                        <TableCell>{item.ticketCount}  {values.type ==="MONTHLY" && "(Per Month)"}</TableCell>
                        <TableCell align="right">
                          <IconButton color="default" size="small" onClick={() => handleItemEdit(item)}>
                            <EditOutlinedIcon />
                          </IconButton>
                          <IconButton color="default" size="small" onClick={() => handleItemRemove(item)}>
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
          {values.items &&
            values.items.some(item => {
              return item.type === "ADDITIONAL";
            }) && (
              <div>
                <strong className="py-2 my-2">Additional Package Item</strong>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Store Branch</TableCell>
                      <TableCell>Offering Category</TableCell>
                      <TableCell>Exchange Rate</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.items &&
                      values.items.length > 0 &&
                      values.items
                        .filter(item => {
                          return item.type === "ADDITIONAL";
                        })
                        .map(item => (
                          <TableRow hover key={item.offeringCategory.id}>
                            <TableCell>{item.applicableStoreBranch.name}</TableCell>
                            <TableCell>{item.offeringCategory.name}</TableCell>
                            <TableCell>{item.exchangeRate}</TableCell>
                            <TableCell align="right">
                              <IconButton color="default" size="small" onClick={() => handleItemEdit(item)}>
                                <EditOutlinedIcon />
                              </IconButton>
                              <IconButton color="default" size="small" onClick={() => handleItemRemove(item)}>
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </div>
            )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => handleItemCreate()}>
            Add Item
          </Button>
          {!touched.items && (!values.items || values.items.length === 0) ? (
            <FormHelperText required>Please click the button below to add items.</FormHelperText>
          ) : (
            <FormHelperText required error={Boolean(touched.items && errors.items)}>
              {touched.items && errors.items}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      {itemCreateFormDialog}
      {itemEditFormDialog}
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  validityPeriod: Yup.number().when("type", {
    is: "PACKAGE",
    then: Yup.number()
      .typeError("Can only be integers")
      .positive("Can only be positive")
      .integer("Can only be integers")
      .required("Required"),
    otherwise: Yup.number().notRequired()
  }),
  items: Yup.array().min(1, "At least one item is required")
});

export const initialValues = {
  id: null,
  name: "",
  type: "PACKAGE",
  validityPeriod: "",
  status: true,
  displayExpirationDate: true,
  items: []
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.name && params.append("name", values.name);
  values.type && params.append("type", values.type);
  values.validityPeriod && params.append("validityPeriod", values.validityPeriod);
  params.append("status", values.status);
  params.append("displayExpirationDate", values.displayExpirationDate);
  values.items &&
    values.items.forEach((item, index) => {
      params.append(`items[${index}].offeringCategory`, item.offeringCategory.id);
      params.append(`items[${index}].type`, item.type);
      params.append(`items[${index}].applicableStoreBranch`, item.applicableStoreBranch.id);
      item.type === "MAIN" && params.append(`applicableStoreBranch`, item.applicableStoreBranch.id);
      item.ticketCount && params.append(`items[${index}].ticketCount`, item.ticketCount);
      item.exchangeRate && params.append(`items[${index}].exchangeRate`, item.exchangeRate);
    });

  return params;
};
