import React from "react";
import { Formik } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validate,
  validationSchema
} from "./AttributeFormDialog";
import { retrieveInstructorName } from "../../../utils/app.util";

const CreateForm = props => {
  const { open, onClose, onEnroll, ...rest } = props;

  return (
    <AttributeFormDialog
      {...rest}
      title="Schedule Class"
      open={open}
      onClose={onClose}
      loading={false}
    />
  );
};

const FormikCreateForm = props => {
  const { onOpen, onClose, onSuccess, parent, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const instructorName = retrieveInstructorName(values.instructor);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Schedule class for instructor "${instructorName}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .post(
        `/api/offerings/${parent.id}/offeringSchedules`,
        createParams(values)
      )
      .then(() => {
        notify(`Class scheduled for instructor "${instructorName}"`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for scheduling data`,
              variant: "warning"
            },
            403: {
              message: `Access denied to schedule classes`,
              variant: "error"
            }
          },
          {
            message: `Unable to schedule class for instructor "${instructorName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      <CreateForm {...rest} onClose={onClose} />
    </Formik>
  );
};

export default FormikCreateForm;
