import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Error404Icon from "../icons/Error404Icon";
import UnexpectedStatusPage from "../components/UnexpectedStatusPage";

const Error404Page = props => {
  return (
    <UnexpectedStatusPage
      {...props}
      iconComponent={Error404Icon}
      message="The page you are looking for does not exist"
      buttonText="Back To Home"
      ButtonProps={{
        component: RouterLink,
        to: "/"
      }}
    />
  );
};

export default Error404Page;
