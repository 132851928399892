import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validate,
  validationSchema
} from "./AttributeFormDialog";
import moment from "moment";
import { extractSelectOption } from "../../../utils/app.util";
import { OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS } from "../../../constants/app.constant";

const EditForm = props => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/offeringScheduleSessions/${entity.id}`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to session "${moment(entity.day).format(
                "YYYY-MM-DD"
              )}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch session "${moment(entity.day).format(
              "YYYY-MM-DD"
            )}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <AttributeFormDialog
      {...rest}
      title="Edit Class Schedule Session"
      open={open}
      onClose={onClose}
      loading={loading}
      showStatus
    />
  );
};

const FormikEditForm = props => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    onSuccess(values, entity);
 
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = receivedValues => {
  receivedValues.instructor || (receivedValues.instructor = null);
  receivedValues.day || (receivedValues.day = null);
  receivedValues.startTime || (receivedValues.startTime = null);
  receivedValues.endTime || (receivedValues.endTime = null);
  receivedValues.status || (receivedValues.status = null);

  receivedValues.scheduledDay &&
    (receivedValues.scheduledDay = moment(
      receivedValues.scheduledDay,
      "YYYY-MM-DD"
    ));
  receivedValues.day &&
    (receivedValues.day = moment(receivedValues.day, "YYYY-MM-DD"));
  receivedValues.startTime &&
    (receivedValues.startTime = moment(receivedValues.startTime, "HH:mm"));
  receivedValues.endTime &&
    (receivedValues.endTime = moment(receivedValues.endTime, "HH:mm"));

  receivedValues.status &&
    (receivedValues.status = extractSelectOption(
      OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS,
      "value",
      receivedValues.status
    ));

  // follow original details
  if(receivedValues.day == null) receivedValues.day = moment(receivedValues.scheduledDay, "YYYY-MM-DD");
  if(receivedValues.instructor == null) receivedValues.instructor = receivedValues.offeringSchedule.instructor;
  if(receivedValues.startTime == null) receivedValues.startTime = moment(receivedValues.offeringSchedule.startTime, "HH:mm");
  if(receivedValues.endTime == null) receivedValues.endTime = moment(receivedValues.offeringSchedule.endTime, "HH:mm");
};
