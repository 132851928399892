import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  rootDefault: {
    color: theme.palette.text.secondary
  },
  rootError: {
    color: fade(theme.palette.error.main, 0.45)
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  icon: {
    width: 120,
    height: "auto"
  },
  iconDefault: {
    fill: theme.palette.text.secondary
  },
  iconError: {
    fill: fade(theme.palette.error.main, 0.45)
  }
}));

const UnexpectedStatusPage = props => {
  const {
    className,
    iconComponent: IconComponent,
    variant = "default",
    message,
    buttonText,
    ButtonProps
  } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        classes[`root${capitalize(variant)}`],
        className
      )}
    >
      <IconComponent
        className={clsx(classes.icon, classes[`icon${capitalize(variant)}`])}
      />
      <Typography
        className={classes.text}
        align="center"
        variant="h6"
        color="inherit"
      >
        {message}
      </Typography>
      {buttonText && (
        <Button
          className={clsx(classes.button)}
          color="primary"
          variant="outlined"
          {...ButtonProps}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default UnexpectedStatusPage;
