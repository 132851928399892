import React, { useState } from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import { retrieveInstructorName } from "../../../utils/app.util";
import { Formik, useFormikContext } from "formik";
import getAllFieldErrors from "../../../utils/api.util";
import moment from "moment";
import AttributeFormDialog, {
    createParams,
    initialValues,
    validate,
    validationSchema
  } from "./AttributeFormDialog";

const EditConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity, schedule, scheduleRemove, sessionList, values, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  const [loading, setLoading] = React.useState(false);
  
  const handleEditConfirm = () => {
    if (!entity) {
      return;
    }

    onClose();

   setLoading(true);

   const displayProgressTimeoutKey = setTimeout(() => {
      notify(
        `Updating session "${(values.day || values.scheduledDay).format(
          "YYYY-MM-DD"
        )}"`
      );
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/offeringScheduleSessions/${values.id}`, createParams(values))
      .then(() => {
        notify(
          `Session "${(values.day || values.scheduledDay).format(
            "YYYY-MM-DD"
          )}" updated`,
          "success"
        );

        // formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
        //   formikActions.setErrors(getAllFieldErrors(error.response));

        //   onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for session "${(
                values.day || values.scheduledDay
              ).format("YYYY-MM-DD")}"`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update sessions`,
              variant: "error"
            }
          },
          {
            message: `Unable to update session "${(
              values.day || values.scheduledDay
            ).format("YYYY-MM-DD")}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        // onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        // formikActions.setSubmitting(false);
      });

  
     
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
        open={open}
        onClose={onClose}
        title="Update Class Schedule Session?"
        message={
            <React.Fragment>
                Session from <b>{moment(entity.day).format("YYYY-MM-DD")}</b> are going to be update. You will not be able to recover the data once updated.
            </React.Fragment>
        }
        onConfirm={handleEditConfirm}
    />
  );
};

export default EditConfirmationDialog;

