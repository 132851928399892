import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING } from "../../../constants/app.constant";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import LabelledStatusBullet from "../../../components/LabelledStatusBullet";

import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import { TableHead } from "@material-ui/core";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/packagePlans/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch package plan "${entity.name}"`,
                variant: "error"
              }
            },
            {
              message: `Unable to fetch package plan "${entity.name}"`,
              variant: "error"
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog {...rest} open={open} onClose={onClose} loading={loading} title="Package Plan Information">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{detailedEntity.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>{detailedEntity.type && PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING[detailedEntity.type]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{detailedEntity.type === "PACKAGE" ? "Validity Period (Days)" : "Validity Period (Months)"}</TableCell>
            <TableCell>{detailedEntity.validityPeriod}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              {entity.status ? <LabelledStatusBullet color="success" label="Active" /> : <LabelledStatusBullet color="default" label="Inactive" />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Display Expiration Date</TableCell>
            <TableCell>
              {entity.displayExpirationDate ? <LabelledStatusBullet color="success" label="Active" /> : <LabelledStatusBullet color="default" label="Inactive" />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Items</TableCell>
            <TableCell>
              {detailedEntity.items && detailedEntity.items.length > 0 && (
                <div>
                  <strong className="py-2 my-2">Main Package Item</strong>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Store Branch</TableCell>
                        <TableCell>Offering Category</TableCell>
                        <TableCell>Ticket Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailedEntity.items
                        .filter(item => {
                          return item.type === "MAIN";
                        })
                        .map(item => (
                          <TableRow hover key={item.offeringCategory.id}>
                            <TableCell>{item.applicableStoreBranch.name}</TableCell>
                            <TableCell>{item.offeringCategory.name}</TableCell>
                            <TableCell>{item.ticketCount} (Ticket Count)</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              {detailedEntity.items &&
                detailedEntity.items.length > 1 &&
                detailedEntity.items.some(item => {
                  return item.type === "ADDITIONAL";
                }) && (
                  <div>
                    <strong className="py-2 my-2">Additional Package Item</strong>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Store Branch</TableCell>
                          <TableCell>Offering Category</TableCell>
                          <TableCell>Exchange Rate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailedEntity.items &&
                          detailedEntity.items.length > 0 &&
                          detailedEntity.items
                            .filter(item => {
                              return item.type === "ADDITIONAL";
                            })
                            .map(item => (
                              <TableRow hover key={item.offeringCategory.id}>
                                <TableCell>{item.applicableStoreBranch.name}</TableCell>
                                <TableCell>{item.offeringCategory.name}</TableCell>
                                {item.type === "MAIN" ? (
                                  <TableCell>{item.ticketCount} (Ticket Count)</TableCell>
                                ) : (
                                  <TableCell>{item.exchangeRate} (Exchange Ratio)</TableCell>
                                )}
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </div>
                )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
