import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikTextArea from "../../../components/inputs/FormikTextArea";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import { useFormikContext } from "formik";
import StoreBranchCreateFormDialog from "./PromotionBranchCreateFormDialog";
import StoreBranchEditFormDialog from "./PromotionBranchEditFormDialog";
import Button from "@material-ui/core/Button";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikKeyboardTimePicker from "../../../components/inputs/FormikKeyboardTimePicker";
import FormikImageDropzone from "../../../components/inputs/FormikImageDropzone";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    ...theme.typography.h5
  }
}));

// import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext();

  const [showAddAllStoreBranchButton, setAddAllStoreBranchButton] = useState(true);

  const [showAddStoreBranchButton, setAddStoreBranchButton] = useState(true);

  /*
  Dialog for create item
   */

  const [storeBranchCreateFormDialogOpen, setStoreBranchCreateFormDialogOpen] = useState(false);

  const handleStoreBranchCreateFormDialogClose = useCallback(() => {
    setStoreBranchCreateFormDialogOpen(false);
  }, []);

  const storeBranchCreateFormDialog = (
    <StoreBranchCreateFormDialog open={storeBranchCreateFormDialogOpen} onClose={handleStoreBranchCreateFormDialogClose} />
  );

  /*
  Dialog for edit item
   */

  const [storeBranchEditFormDialogState, setStoreBranchEditFormDialogState] = useState({
    open: false
  });

  const handleStoreBranchEditFormDialogClose = useCallback(() => {
    setStoreBranchEditFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const storeBranchEditFormDialog = <StoreBranchEditFormDialog {...storeBranchEditFormDialogState} onClose={handleStoreBranchEditFormDialogClose} />;

  /*
  Handlers
   */

  const addAllStoreBranches = async () => {
    const request = await axios.get("/api/storeBranches/variants/references");
    setFieldTouched("promotionBranches", true, false);
    const allPromotionBranches = [];
    request["data"].forEach(datum => {
      const branch = { applicableStoreBranch: { ...datum } };
      allPromotionBranches.push(branch);
    });
    setFieldValue("promotionBranches", allPromotionBranches);
    setAddAllStoreBranchButton(false);
    setAddStoreBranchButton(false);
  };

  const removeAllStoreBranches = () => {
    setFieldTouched("promotionBranches", true, false);
    setFieldValue("promotionBranches", []);
    setAddAllStoreBranchButton(true);
    setAddStoreBranchButton(true);
  };

  const handleStoreBranchCreate = () => {
    setStoreBranchCreateFormDialogOpen(true);
  };

  const handleStoreBranchEdit = item => {
    setStoreBranchEditFormDialogState(prevState => ({
      ...prevState,
      item: item,
      open: true
    }));
  };

  const handleStoreBranchRemove = pendingRemoveItem => {
    setFieldTouched("promotionBranches", true, false);

    setFieldValue(
      "promotionBranches",
      values.promotionBranches.filter(promotionBranch => promotionBranch !== pendingRemoveItem)
    );
  };

  return (
    <FormikFormDialog {...rest} title={title} open={open} loading={loading} onClose={onClose} validate={validate}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormikImageDropzone name="imageUrl" policy="PROMOTION_IMAGE" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <FormikTextField fullWidth required label="Title" name="title" variant="outlined" margin="dense" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikKeyboardDatePicker fullWidth required name="startDate" label="Start Date" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikKeyboardDatePicker fullWidth disablePast required name="endDate" label="End Date" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikKeyboardTimePicker fullWidth required name="startTime" label="Start Time" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikKeyboardTimePicker fullWidth required name="endTime" label="End Time" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormikTextArea fullWidth label="Description" name="description" variant="outlined" rows="8" />
          </Grid>
          <Grid item xs={12}>
            {values.promotionBranches && values.promotionBranches.length > 0 && (
              <div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Store Branch</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.promotionBranches.map(promotionBranch => (
                      <TableRow hover key={promotionBranch.applicableStoreBranch.id}>
                        <TableCell>{promotionBranch.applicableStoreBranch.name}</TableCell>
                        <TableCell align="right">
                          <IconButton color="default" size="small" onClick={() => handleStoreBranchEdit(promotionBranch)}>
                            <EditOutlinedIcon />
                          </IconButton>
                          <IconButton color="default" size="small" onClick={() => handleStoreBranchRemove(promotionBranch)}>
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {!touched.promotionBranches && (!values.promotionBranches || values.promotionBranches.length === 0) ? (
              <FormHelperText required>Please click the button below to add ALL STORE BRANCHES or INDIVIDUAL STORE BRANCH.</FormHelperText>
            ) : (
              <FormHelperText required error={Boolean(touched.promotionBranches && errors.promotionBranches)}>
                {touched.promotionBranches && errors.promotionBranches}
              </FormHelperText>
            )}
            {showAddAllStoreBranchButton ? (
              <Button variant="outlined" onClick={() => addAllStoreBranches()}>
                Add All Store Branches
              </Button>
            ) : (
              <Button variant="outlined" onClick={() => removeAllStoreBranches()}>
                Remove All Store Branches
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {showAddStoreBranchButton && (
              <Button variant="outlined" onClick={() => handleStoreBranchCreate()}>
                Add Store Branch
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormikSwitch
              name="displayStatus"
              label="Display Status"
              description="Indicate if this promotion should be shown"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
        </Grid>
        {storeBranchCreateFormDialog}
        {storeBranchEditFormDialog}
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  startDate: Yup.object()
    .nullable(true)
    .required("Required"),
  endDate: Yup.object()
    .nullable(true)
    .required("Required"),
  startTime: Yup.object()
    .nullable(true)
    .required("Required"),
  endTime: Yup.object()
    .nullable(true)
    .required("Required"),
  promotionBranches: Yup.array().min(1, "At least one store branch is required")
});

const validate = values => {
  const errors = {};

  if (values.startDate && values.endDate) {
    if (values.endDate.isSameOrBefore(values.startDate)) {
      errors.endDate = "Cannot be earlier than or equal to start date";
    }
  }

  if (values.startTime && values.endTime) {
    if (values.endTime.isSameOrBefore(values.startTime)) {
      errors.endTime = "Cannot be earlier than or equal to start time";
    }
  }

  return errors;
};

export const initialValues = {
  id: null,
  title: "",
  description: "",
  imageUrl: null,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  displayStatus: false,
  promotionBranches: [],
  files: []
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.title && params.append("title", values.title);
  values.description && params.append("description", values.description);
  values.imageUrl && params.append("imageUrl", values.imageUrl);
  values.startDate && params.append("startDate", values.startDate.format("YYYY-MM-DD"));
  values.endDate && params.append("endDate", values.endDate.format("YYYY-MM-DD"));
  values.startTime && params.append("startTime", values.startTime.format("HH:mm"));
  values.endTime && params.append("endTime", values.endTime.format("HH:mm"));
  values.displayStatus && params.append("displayStatus", values.displayStatus);
  values.promotionBranches &&
    values.promotionBranches.forEach((promotionBranch, index) => {
      params.append(`promotionBranches[${index}].applicableStoreBranch`, promotionBranch.applicableStoreBranch.id);
    });

  return params;
};
