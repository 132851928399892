import React, { useState } from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import axios from "axios";
import { Formik, useFormikContext } from "formik";
import getAllFieldErrors from "../../../utils/api.util";
import moment from "moment";

const EditSessionTimeConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity, schedule, scheduleRemove, sessionList, sessionTime, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  const [loading, setLoading] = React.useState(false);

  const handleEditSessionTime = () => {
    if (!entity) {
      return;
    }

    onClose();

   setLoading(true);

    var filterSession = [];
        for(var i = 0 ; i < sessionList.sessionList.length; i++){
            if((moment(sessionList.sessionList[i].scheduledDay, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleRemove.endDate, 'YYYY-MM-DD')) && 
                moment(sessionList.sessionList[i].scheduledDay, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleRemove.startDate, 'YYYY-MM-DD'))) ) {
                    filterSession.push(sessionList.sessionList[i]);
                }
        }
  
        for (var i = 0; i < filterSession.length; i++) {
          axios
          .put(`/api/offeringScheduleSessions/${filterSession[i].id}`, createParams(schedule, filterSession[i], sessionTime))
          .then(() => {

          })
          .catch(error => {
            if (error.response.status === 400) {

            }

            notifyApiError(
              error.response.status,
              {
                400: {
                  message: `Invalid inputs found for session `,
                  variant: "warning"
                },
                403: {
                  message: `Access denied to update sessions`,
                  variant: "error"
                }
              },
              {
                message: `Unable to update session `,
                variant: "error"
              }
            );
          })
        }

        notify(
            `Session time from "${scheduleRemove.startDate}" to "${scheduleRemove.endDate}" are updated`,
            "success"
          );
     
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
        open={open}
        onClose={onClose}
        title="Edit Class Schedule?"
        message={
            <React.Fragment>
            Session time from <b>{scheduleRemove.startDate}</b> to <b>{scheduleRemove.endDate}</b> are going to be updated. You will not be able to recover the data once removed.
            </React.Fragment>
        }
        onConfirm={handleEditSessionTime}
    />
  );
};

export default EditSessionTimeConfirmationDialog;

  export const createParams = (schedule, filterSession, sessionTime) => {
    const params = new URLSearchParams();

    schedule.instructor && params.append("instructor", schedule.instructorId);
    params.append("day", filterSession.scheduledDay);
    schedule.startTime && params.append("startTime", sessionTime.startTime.format("HH:mm"));
    schedule.endTime && params.append("endTime", sessionTime.endTime.format("HH:mm"));
  
    params.append("status", filterSession.status);
  
    return params;
  };

