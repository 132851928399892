import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useFormikContext } from "formik";
import { makeStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  group: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    marginLeft: -theme.spacing(0.5),
    marginRight: -theme.spacing(0.5)
  },
  endOfMonth: {
    borderRadius: "20px !important",
    "&:not(:first-child)": {
      width: theme.spacing(0.5) * 4 + 120
    },
    "&:first-child": {
      width: theme.spacing(0.5) * 4 + 120
    }
  }
}));

const StyledToggleButton = withStyles(theme => ({
  root: {
    width: 40,
    "&:not(:first-child)": {
      borderRadius: "50%",
      border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5)
    },
    "&:first-child": {
      borderRadius: "50%",
      border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5)
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.secondary.main, 0.05)
    },
    "&$selected": {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.secondary.main, 0.12),
      "&:hover": {
        backgroundColor: fade(theme.palette.secondary.main, 0.15)
      },
      "& + &": {
        borderRadius: "50%",
        border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5)
      }
    }
  },
  selected: {}
}))(ToggleButton);

const FormikWeekToggleButtonGroup = props => {
  const { name, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = useFormikContext();

  return (
    <ToggleButtonGroup
      className={classes.group}
      size="small"
      {...rest}
      value={values[name]}
      onChange={(event, value) => {
        setFieldTouched(name, true, false);
        setFieldValue(name, value);
      }}
    >
      <StyledToggleButton value={1} disabled={isSubmitting}>
        <Typography variant="button">1</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={2} disabled={isSubmitting}>
        <Typography variant="button">2</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={3} disabled={isSubmitting}>
        <Typography variant="button">3</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={4} disabled={isSubmitting}>
        <Typography variant="button">4</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={5} disabled={isSubmitting}>
        <Typography variant="button">5</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={6} disabled={isSubmitting}>
        <Typography variant="button">6</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={7} disabled={isSubmitting}>
        <Typography variant="button">7</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={8} disabled={isSubmitting}>
        <Typography variant="button">8</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={9} disabled={isSubmitting}>
        <Typography variant="button">9</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={10} disabled={isSubmitting}>
        <Typography variant="button">10</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={11} disabled={isSubmitting}>
        <Typography variant="button">11</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={12} disabled={isSubmitting}>
        <Typography variant="button">12</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={13} disabled={isSubmitting}>
        <Typography variant="button">13</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={14} disabled={isSubmitting}>
        <Typography variant="button">14</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={15} disabled={isSubmitting}>
        <Typography variant="button">15</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={16} disabled={isSubmitting}>
        <Typography variant="button">16</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={17} disabled={isSubmitting}>
        <Typography variant="button">17</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={18} disabled={isSubmitting}>
        <Typography variant="button">18</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={19} disabled={isSubmitting}>
        <Typography variant="button">19</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={20} disabled={isSubmitting}>
        <Typography variant="button">20</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={21} disabled={isSubmitting}>
        <Typography variant="button">21</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={22} disabled={isSubmitting}>
        <Typography variant="button">22</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={23} disabled={isSubmitting}>
        <Typography variant="button">23</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={24} disabled={isSubmitting}>
        <Typography variant="button">24</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={25} disabled={isSubmitting}>
        <Typography variant="button">25</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={26} disabled={isSubmitting}>
        <Typography variant="button">26</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={27} disabled={isSubmitting}>
        <Typography variant="button">27</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={28} disabled={isSubmitting}>
        <Typography variant="button">28</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={29} disabled={isSubmitting}>
        <Typography variant="button">29</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={30} disabled={isSubmitting}>
        <Typography variant="button">30</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={31} disabled={isSubmitting}>
        <Typography variant="button">31</Typography>
      </StyledToggleButton>
      <StyledToggleButton
        className={classes.endOfMonth}
        value={32}
        disabled={isSubmitting}
      >
        <Typography variant="button">End of Month</Typography>
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default FormikWeekToggleButtonGroup;
