import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  linkContainer: {
    flexGrow: 1
  }
}));

export default React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.linkContainer}>
      <RouterLink {...props} />
    </div>
  );
});
