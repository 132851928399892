export default {
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  h1: {
    fontWeight: 500,
    fontSize: "35px",
    letterSpacing: "-0.24px",
    lineHeight: "40px"
  },
  h2: {
    fontWeight: 500,
    fontSize: "29px",
    letterSpacing: "-0.24px",
    lineHeight: "32px"
  },
  h3: {
    fontWeight: 500,
    fontSize: "24px",
    letterSpacing: "-0.06px",
    lineHeight: "28px"
  },
  h4: {
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "-0.06px",
    lineHeight: "24px"
  },
  h5: {
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "20px"
  },
  h6: {
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "20px"
  },
  subtitle1: {
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "25px"
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px"
  },
  body1: {
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px"
  },
  body2: {
    fontSize: "12px",
    letterSpacing: "-0.04px",
    lineHeight: "18px"
  },
  button: {
    fontSize: "14px"
  },
  caption: {
    fontSize: "11px",
    letterSpacing: "0.33px",
    lineHeight: "13px"
  },
  overline: {
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: "0.33px",
    lineHeight: "13px",
    textTransform: "uppercase"
  }
};
