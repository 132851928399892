import securityService from "./security.service";
import axiosConfigService from "../config/axios.config";

class apiService {
  isErrorResponse = response => {
    if (!response || !response.data || !response.status) {
      return false;
    }

    let data = response.data;
    let status = response.status;

    if (status && status >= 400 && status < 500) {
      // Client side error
      return (
        data && typeof data === "object" && "code" in data && "message" in data
      );
    } else if (status && status >= 500) {
      // Server side error
      return (
        data && typeof data === "object" && "code" in data && "message" in data
      );
    }

    return false;
  };

  handleErrorResponse = response => {
    let status = response.status;

    if (status === 401) {
      // Handle unauthenticated error
      securityService.removeAccessToken();
      axiosConfigService.removeAuthorizationHeader();
    }
  };
}

export default new apiService();
