import React from "react";
import Grid from "@material-ui/core/Grid";
import FormikInstructorSelect from "../../../components/inputs/FormikInstructorSelect";

const InstructorForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <FormikInstructorSelect
          required
          name="instructor"
          label="Instructor"
          fullWidth
          margin="dense"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default InstructorForm;
