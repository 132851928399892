import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  searchButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0),
    height: theme.spacing(5)
  }
}));

const CustomerUserSearchForm = props => {
  const classes = useStyles();

  const { loading, searchText, onSearchTextChange, onSubmit } = props;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={9}>
          <TextField
            fullWidth
            label="Client Information"
            variant="outlined"
            margin="dense"
            name={"customerUserInfo"}
            value={searchText}
            onChange={event => onSearchTextChange(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={3}>
          <div className={classes.actions}>
            <Button
              className={classes.searchButton}
              variant="contained"
              color="secondary"
              disabled={loading || !searchText}
              onClick={() => onSubmit(searchText)}
            >
              Search
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerUserSearchForm;
