import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";
import { createFilterOptions } from "@material-ui/lab";

export default function(props) {
  const { index, open, onClose, title, loading, promotionBranch, promotionBranches, ...rest } = props;

  let storeBranchIds = promotionBranches.map(storeBranch => storeBranch.applicableStoreBranch.id);
  if (promotionBranch) {
    storeBranchIds = storeBranchIds.filter(entry => entry !== promotionBranch.applicableStoreBranch.id);
  }

  const defaultFilterOptions = createFilterOptions({
    stringify: option => option.name
  });

  const filterOptions = (options, state) => {
    const candidateOptions = options.filter(option => !storeBranchIds.includes(option.id));

    return defaultFilterOptions(candidateOptions, state);
  };

  return (
    <FormikFormDialog {...rest} title={title} open={open} loading={loading} onClose={onClose}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikStoreBranchSelect
            fullWidth
            name="applicableStoreBranch"
            label="Store Branch"
            margin="dense"
            variant="outlined"
            filterOptions={filterOptions}
          />
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  applicableStoreBranch: Yup.object()
    .nullable(true)
    .required("Required")
});

export const initialValues = {
  applicableStoreBranch: null
};
