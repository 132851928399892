import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import {PROGRESS_TIME_LIMIT} from "../../../config/app.config";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {getCustomerUserName} from "../../../utils/app.util";

export default function (props) {
  const {open, onClose, onBefore, onAfter, onSuccess, onCancel, entity} = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  const [bypassLateCancel, setBypassLateCancel] = React.useState(false);

  const handleCheckBox = (event) => {
    setBypassLateCancel(event.target.checked);
  };

  const onDialogClose = () => {
    onClose();
    setBypassLateCancel(false);
  }

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onDialogClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(
        `Cancelling client enrollment "${getCustomerUserName(
          entity.customerUser
        )}"`
      );
    }, PROGRESS_TIME_LIMIT);

    const params = new URLSearchParams();

    params.append("bypassLateCancel", bypassLateCancel);
    // params.append("purchasedPackagePlan", entity.purchasedPackagePlan.id);

    axios
      .post(`/api/transcripts/actions/cancel/${entity.id}`, params)
      .then(() => {
        notify(
          `Client enrollment "${getCustomerUserName(
            entity.customerUser
          )}" cancelled`,
          "success"
        );

        onSuccess();
        onCancel();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to cancel client enrollment "${getCustomerUserName(
                entity.customerUser
              )}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to cancel client enrollment "${getCustomerUserName(
              entity.customerUser
            )}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onDialogClose}
      title="Cancel Client Enrollment?"
      message={
        <React.Fragment>
          Client enrollment <b>{getCustomerUserName(entity.customerUser)}</b> is
          going to be cancelled. You will not be able to recover the data once
          cancelled.
          <br/>
          <FormControlLabel
            control={<Checkbox checked={bypassLateCancel} onChange={handleCheckBox}/>}
            label="bypass late cancel (ticket will not be deducted)"
          />
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
}
