import React, { useCallback, useState } from "react";
import Frame from "../../components/Frame";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ReportCard from "./components/ReportCard";
import Typography from "@material-ui/core/Typography";
import CancelledTranscriptReportConfigFormDialog from "./components/CancelledTranscriptReportConfigFormDialog";
import PackagePlanBalanceReportConfigFormDialog from "./components/PackagePlanBalanceReportConfigFormDialog";
import LastAccessCustomerUserReportConfigFormDialog from "./components/LastAccessCustomerUserReportConfigFormDialog";
import ExpirationDateCustomerUserReportConfigFormDialog from "./components/ExpirationDateCustomerUserReportConfigFormDialog";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    padding: theme.spacing(6)
  },
  card: {
    width: theme.spacing(28)
  },
  cardContent: {
    height: theme.spacing(24)
  }
}));

export default function ReportList() {
  const classes = useStyles();

  /*
  Cancelled transcript report config dialog
   */

  const [
    cancelledTranscriptReportConfigFormDialogState,
    setCancelledTranscriptReportConfigFormDialogState
  ] = useState(false);

  const handleCancelledTranscriptReportConfigFormDialogOpen = useCallback(() => {
    setCancelledTranscriptReportConfigFormDialogState(true);
  }, []);

  const handleCancelledTranscriptReportConfigFormDialogClose = useCallback(() => {
    setCancelledTranscriptReportConfigFormDialogState(false);
  }, []);

  const cancelledTranscriptReportConfigFormDialog = (
    <CancelledTranscriptReportConfigFormDialog
      open={cancelledTranscriptReportConfigFormDialogState}
      onOpen={handleCancelledTranscriptReportConfigFormDialogOpen}
      onClose={handleCancelledTranscriptReportConfigFormDialogClose}
    />
  );

  /*
  Package plan balance report config dialog
   */

  const [
    packagePlanBalanceReportConfigFormDialogState,
    setPackagePlanBalanceReportConfigFormDialogState
  ] = useState(false);

  const handlePackagePlanBalanceReportConfigFormDialogOpen = useCallback(() => {
    setPackagePlanBalanceReportConfigFormDialogState(true);
  }, []);

  const handlePackagePlanBalanceReportConfigFormDialogClose = useCallback(() => {
    setPackagePlanBalanceReportConfigFormDialogState(false);
  }, []);

  const packagePlanBalanceReportConfigFormDialog = (
    <PackagePlanBalanceReportConfigFormDialog
      open={packagePlanBalanceReportConfigFormDialogState}
      onOpen={handlePackagePlanBalanceReportConfigFormDialogOpen}
      onClose={handlePackagePlanBalanceReportConfigFormDialogClose}
    />
  );

  /*
  Last access customer user report config dialog
   */

  const [
    lastAccessCustomerUserReportConfigFormDialogState,
    setLastAccessCustomerUserReportConfigFormDialogState
  ] = useState(false);

  const handleLastAccessCustomerUserReportConfigFormDialogOpen = useCallback(() => {
    setLastAccessCustomerUserReportConfigFormDialogState(true);
  }, []);

  const handleLastAccessCustomerUserReportConfigFormDialogClose = useCallback(() => {
    setLastAccessCustomerUserReportConfigFormDialogState(false);
  }, []);

  const lastAccessCustomerUserReportConfigFormDialog = (
    <LastAccessCustomerUserReportConfigFormDialog
      open={lastAccessCustomerUserReportConfigFormDialogState}
      onOpen={handleLastAccessCustomerUserReportConfigFormDialogOpen}
      onClose={handleLastAccessCustomerUserReportConfigFormDialogClose}
    />
  );

  /*
  Expiration date customer user report config dialog
   */

  const [
    expirationDateCustomerUserReportConfigFormDialogState,
    setExpirationDateCustomerUserReportConfigFormDialogState
  ] = useState(false);

  const handleExpirationDateCustomerUserReportConfigFormDialogOpen = useCallback(() => {
    setExpirationDateCustomerUserReportConfigFormDialogState(true);
  }, []);

  const handleExpirationDateCustomerUserReportConfigFormDialogClose = useCallback(() => {
    setExpirationDateCustomerUserReportConfigFormDialogState(false);
  }, []);

  const expirationDateCustomerUserReportConfigFormDialog = (
    <ExpirationDateCustomerUserReportConfigFormDialog
      open={expirationDateCustomerUserReportConfigFormDialogState}
      onOpen={handleExpirationDateCustomerUserReportConfigFormDialogOpen}
      onClose={handleExpirationDateCustomerUserReportConfigFormDialogClose}
    />
  );

  return (
    <Frame className={classes.root}>
      <Typography className={classes.title} variant="h1" align="center">
        System Reports
      </Typography>
      <Grid container justify="center" spacing={2}>
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Account Balance"
            onStart={handlePackagePlanBalanceReportConfigFormDialogOpen}
          >
            Show account balances for clients. Clients can be filtered by
            specifying a more-than amount.
          </ReportCard>
          {packagePlanBalanceReportConfigFormDialog}
        </Grid>
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Class Cancellation"
            onStart={handleCancelledTranscriptReportConfigFormDialogOpen}
          >
            Show cancelled classes for clients. Classes can be filtered by their
            start date and end date.
          </ReportCard>
          {cancelledTranscriptReportConfigFormDialog}
        </Grid>
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Client Membership"
            onStart={handleLastAccessCustomerUserReportConfigFormDialogOpen}
          >
            Show information for clients. Clients can be filtered by their age,
            sex.
          </ReportCard>
          {lastAccessCustomerUserReportConfigFormDialog}
        </Grid>
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Package Expiration"
            onStart={handleExpirationDateCustomerUserReportConfigFormDialogOpen}
          >
            Show clients whose packages are going to expire. Clients can be
            filtered by expiring start date and expiring end date.
          </ReportCard>
          {expirationDateCustomerUserReportConfigFormDialog}
        </Grid>
      </Grid>
    </Frame>
  );
}
