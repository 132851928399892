import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import localeService from "../../services/locale.service";
import { IntlProvider } from "react-intl";
import { MESSAGES } from "../../constants/locale.constant";
import { TERMS_AND_CONDITIONS_EN_PATH } from "../../constants/route.constant";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%"
  },
  locale: {
    margin: theme.spacing(6, 0, 1)
  },
  versionDiv: {
    float: "right"
  }
}));

export default function TermsAndConditionsZhPage() {
  const classes = useStyles();

  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  return (
    <IntlProvider locale={locale} messages={MESSAGES[locale]}>
      <Grid
        component="main"
        justify="space-evenly"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} >
          <div className={classes.panel}>

          <div  className={classes.versionDiv}>
            <Link to={TERMS_AND_CONDITIONS_EN_PATH}>
              English Version
            </Link>
          </div>

          <h2><b>條款和條件</b></h2>
            <pre>
{`
會員確認為保障其會員的安全和權益，Webz Design Company將按照其訂立規則和規例營運，並同意遵守這些規則和規例，以及Webz Design Company其後不時制定和公佈的規則和規例。這些規則和規例將不時以引用方式加入此協議。
Webz Design Company保留單獨及絕對酌情權在不需提前通知的情況下不時調整設施、設備、營業時間、服務、規例和政策。會員需同意接受此類合理更改，以作為使用會籍的其中一項規例。

 
2. 會員知悉：
a) 在任何情況下會員均不可移動健身器材或以Webz Design Company未許可的方式使用器材;

b) 會員需要在每次使用器材後，以Webz Design Company提供的工具擦拭所有使用過的器材;

c) 此會籍只限會員使用，而不能供其他人士進入，如發現會員違反此規定，Webz Design Company有權即時終止會籍並向會員收取或等同當時適用的收費表上一個月會籍的月費(以較高者為準)作為罰金;

d) 會員不可誤用器材，應只根據設施裡展示的守則使用器材。

e) 會員須最少年滿18歲，除非Webz Design Company另作許可。

f) 會員應以寧靜、良好品格的方式進行活動，盡量避免逕生影響其他會員使用設施的滋擾。任何褻瀆、猥褻的用語和/或行為都不會被容忍。如Webz Design Company在其單獨酌情權下認為任何行為是冒犯、有機會損害、危害或虐待他人，將不會容忍以及會成為Webz Design Company終止會員會籍的原因;

g) Webz Design Company不會負責任何違失或失竊的物品;

h) 會員不應長時間佔用任何器材並應容許其他等候使用器材的人仕輪流使用器材;及|

i) 設施的室內環境由Webz Design Company控制，以確保大部分會員能在最合適的環境下進行活動。會員不應調整或要求調整任何室內環境的控制，以及不應基於任何理由打開任何設施內的大門或窗戶。

 
3. 設施內嚴禁飲酒、吸毒(包括類固醇)和吸煙。會員同意不會在毒品、酒精或藥物以致影響會員操作器材的情況下使用設施或進行任何Webz Design Company的活動。嚴禁使用任何類型的武器。
嚴禁在設施範圍內拍照、攝錄、拍攝影片或錄音，除非已事先獲得Webz Design Company管理層的許可。Webz Design Company保留權利在其單獨酌情權下規管在設施範圍內食用食物或飲品，或使用外來的器材。嚴禁在設施範圍內或中心內進行賭博或遊戲。

 
4. Webz Design Company要求會員在設施內須穿著適當衣物和鞋履。適當衣物包括健身短褲、T-Shirts、跑步裝束、拉筋裝束和運動裝束。街頭服裝和牛仔褲不視為適當衣服。設施內嚴禁穿著街頭服裝和黑皮鞋。

 
5. Webz Design Company可能不時對其設施的使用政策和守則進行更新，如有任何前述的更新，將於其網站展示通知以知會各會員相關使用政策和守則的重大更改。

 
6. 會員同意和知悉其自身存在的問題、困難和/或喪失能力的情況有可能妨礙會員全面使用及/或享受根據此協議由Webz Design Company提供的設施和/或服務，而會員仍有責任根據此協議全數支付會籍和其他相關費用，
Webz Design Company在任何情況下均不會向會員退還根據此協議已由會員支付的任何費用。
`}
            </pre>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
