import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LOGIN_PATH, FORGET_PASSWORD_PATH, NEW_PASSWORD_PATH,  PRIVACY_ZH_PATH, PRIVACY_EN_PATH, TERMS_AND_CONDITIONS_ZH_PATH, TERMS_AND_CONDITIONS_EN_PATH } from "./constants/route.constant";
import LoginPage from "./pages/LoginPage";
import ForgetPasswordPage from "./pages/ResetPasswordPage";
import SecuredRoute from "./security/SecuredRoute";
import SecurityFilter from "./security/SecurityFilter";
import SwitchableLayout from "./layouts/SwitchableLayout";
import { ThemeProvider } from "@material-ui/core/styles";
import store from "./stores";
import theme from "./themes";
import "./init/axios.init";
import { CssBaseline } from "@material-ui/core";
import PrivacyZhPage from "pages/PrivacyZhPage/PrivacyZhPage";
import PrivacyEnPage from "pages/PrivacyEnPage/PrivacyEnPage";
import TermsAndConditionsZhPage from "pages/TermsAndConditionsZhPage/TermsAndConditionsZhPage";
import TermsAndConditionsEnPage from "pages/TermsAndConditionsEnPage/TermsAndConditionsEnPage";

function App() {
  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <SecurityFilter>
            <Switch>
              <Route path={LOGIN_PATH} component={LoginPage} />
              <Route path={PRIVACY_ZH_PATH} component={PrivacyZhPage} />
              <Route path={PRIVACY_EN_PATH} component={PrivacyEnPage} />
              <Route path={TERMS_AND_CONDITIONS_ZH_PATH} component={TermsAndConditionsZhPage} />
              <Route path={TERMS_AND_CONDITIONS_EN_PATH} component={TermsAndConditionsEnPage} />             
              <Route path={FORGET_PASSWORD_PATH} component={ForgetPasswordPage} />
              <SecuredRoute component={SwitchableLayout} />
            </Switch>
          </SecurityFilter>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
