import React from "react";
import * as Yup from "yup";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import WizardStepCard from "./WizardStepCard";
import InstructorForm from "./InstructorForm";
import CapacityForm from "./CapacityForm";
import RecurringPatternForm from "./RecurringPatternForm";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import StoreBranchForm from "./StoreBranchForm";

const useStyles = makeStyles({
  form: {
    width: 836
  }
});

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
      paperClassName={classes.form}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <WizardStepCard order={1} title="Instructor">
          <InstructorForm />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        <WizardStepCard order={2} title="Recurring Pattern">
          <RecurringPatternForm />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        <WizardStepCard order={3} title="Capacity">
          <CapacityForm />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        <WizardStepCard order={4} title="Branch">
          <StoreBranchForm />
        </WizardStepCard>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  instructor: Yup.object()
    .nullable(true)
    .required("Required"),
  startDate: Yup.object()
    .nullable(true)
    .required("Required"),
  endDate: Yup.object()
    .nullable(true)
    .required("Required"),
  startTime: Yup.object()
    .nullable(true)
    .required("Required"),
  endTime: Yup.object()
    .nullable(true)
    .required("Required"),
  // capacity: Yup.number()
  //   .typeError("Must be an integer")
  //   .integer("Can only be integers")
  //   .min(0, "Can not be smaller than 0"),
  onlineCapacity: Yup.number()
    .typeError("Must be an integer")
    .integer("Can only be integers")
    .min(0, "Can not be smaller than 0"),
  // .max(Yup.ref("capacity"), "Cannot exceed the capacity"),
  waitListCapacity: Yup.number()
    .typeError("Must be an integer")
    .integer("Can only be integers")
    .min(0, "Can not be smaller than 0")
});

export const validate = values => {
  const errors = {};

  if (values.startDate && values.endDate) {
    if (values.endDate.isSameOrBefore(values.startDate)) {
      errors.endDate = "Cannot be earlier than or equal to start date";
    }
  }

  if (values.startTime && values.endTime) {
    if (values.endTime.isSameOrBefore(values.startTime)) {
      errors.endTime = "Cannot be earlier than or equal to start time";
    }
  }

  return errors;
};

export const initialValues = {
  id: null,
  recurringType: "WEEKLY",
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  recurringOrders: [],
  // capacity: "",
  onlineCapacity: "",
  waitListCapacity: "",
  publicAvailable: 1,
  instructor: null
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.instructor && params.append("instructor", values.instructor.id);
  values.startDate &&
    params.append("startDate", values.startDate.format("YYYY-MM-DD"));
  values.endDate &&
    params.append("endDate", values.endDate.format("YYYY-MM-DD"));
  values.startTime &&
    params.append("startTime", values.startTime.format("HH:mm"));
  values.endTime && params.append("endTime", values.endTime.format("HH:mm"));

  // values.capacity && params.append("capacity", values.capacity);
  values.onlineCapacity &&
    params.append("onlineCapacity", values.onlineCapacity);
  values.waitListCapacity &&
    params.append("waitListCapacity", values.waitListCapacity);

  values.recurringType && params.append("recurringType", values.recurringType);

  values.recurringOrders &&
    values.recurringOrders.forEach((recurringOrder, index) => {
      params.append(`recurringOrders[${index}]`, recurringOrder);
    });

  values.publicAvailable &&
      params.append("publicAvailable", values.publicAvailable);
 
  values.storeBranch && params.append("storeBranch", values.storeBranch.id);

  return params;
};
