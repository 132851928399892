import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";

const ManagementPageHeader = props => {
  const { onCreate, onEmailDialog } = props;

  const { isCreateCustomerUserAllowed } = useAccessControl();

  return (
    <PageHeader
      section="Client Management"
      title="Clients"
      right={
        isCreateCustomerUserAllowed() ? (
          <React.Fragment>
            <Button color="secondary" variant="contained" onClick={onCreate}>
              Add Client
            </Button>
            {/* <Button color="primary" variant="contained" style={{ marginLeft: 5 }} onClick={onEmailDialog}>
              Email Clients
            </Button> */}
          </React.Fragment>
        ) : null
      }
    />
  );
};

export default ManagementPageHeader;
