import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { Formik, useFormikContext } from "formik";
import oauth2Service from "../../../services/oauth2.service";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sessionStatusLoadLogin } from "../../../security/SecurityFilter/actions/sessionStatus.action";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { LOGIN_PATH, FORGET_PASSWORD_PATH } from "../../../constants/route.constant";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 3)
  },
  field: {
    marginBottom: theme.spacing(2)
  }
}));

const loginFormSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, <FormattedMessage id="login.form.username.error.length.min" />)
    .max(50, <FormattedMessage id="login.form.username.error.length.max" />)
    .required(<FormattedMessage id="login.form.username.error.required" />),
  password: Yup.string()
    .min(6, <FormattedMessage id="login.form.password.error.length.min" />)
    .max(50, <FormattedMessage id="login.form.password.error.length.max" />)
    .required(<FormattedMessage id="login.form.password.error.required" />)
});

function LoginForm() {
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext();

  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <FormikTextField
        className={classes.field}
        id="username"
        name="username"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="login.form.username" />}
        autoComplete="username"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <FormikTextField
        className={classes.field}
        id="password"
        name="password"
        type="password"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="login.form.password" />}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <Box position="relative">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting || !isValid || !dirty}
        >
          {!isSubmitting ? (
            <FormattedMessage id="login.form.button.login" />
          ) : (
            <FormattedMessage id="login.form.button.loading" />
          )}
        </Button>
      </Box>
      <Grid container>
        <Grid item xs>
          <Link href={FORGET_PASSWORD_PATH} variant="body1">
            <FormattedMessage id="login.forgetPassword" />
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

function FormikLoginForm(props) {
  return (
    <Formik
      validationSchema={loginFormSchema}
      onSubmit={(values, formikActions) => {
        return oauth2Service
          .requestToken(values.username, values.password)
          .then(() => {
            formikActions.setSubmitting(false);

            props.sessionStatusLoadLogin();
          })
          .catch(response => {
            if (response.status === 400) {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.failed" />
              );
            } else if (response.status === 500) {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.server" />
              );
            } else {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.unknown" />
              );
            }

            formikActions.setSubmitting(false);
          });
      }}
      initialValues={{ username: "", password: "" }}
      component={LoginForm}
    />
  );
}

export default connect(
  null,
  { sessionStatusLoadLogin }
)(FormikLoginForm);
