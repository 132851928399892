import React from "react";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import getAllFieldErrors from "../../../utils/api.util";
import { Formik } from "formik";
import moment from "moment";
import fileDownload from "js-file-download";
import EmptyEnabledFormikFormDialog from "../../../components/dialogs/EmptyEnabledFormikFormDialog";

const CancelledTranscriptReportConfigFormDialog = props => {
  const { open, onClose, ...rest } = props;

  return (
    <EmptyEnabledFormikFormDialog
      {...rest}
      title="Class Cancellation Report"
      open={open}
      onClose={onClose}
      loading={false}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker name="startDate" label="Start Date" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker name="endDate" label="End Date" />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </EmptyEnabledFormikFormDialog>
  );
};

const FormikCancelledTranscriptReportConfigFormDialog = props => {
  const { onOpen, onClose, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Running class cancellation report`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .get("/api/reports/cancelledTranscripts", {
        params: {
          startDate: values.startDate
            ? moment(values.startDate).format("YYYY-MM-DD")
            : undefined,
          endDate: values.endDate
            ? moment(values.endDate).format("YYYY-MM-DD")
            : undefined
        },
        responseType: "blob"
      })
      .then(response => {
        notify(`Class cancellation report completed`, "success");

        formikActions.resetForm();

        fileDownload(response.data, "class-cancellation-report.xls");
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning"
            },
            403: {
              message: `Access denied to run report`,
              variant: "error"
            }
          },
          {
            message: `Unable to run report`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      <CancelledTranscriptReportConfigFormDialog {...rest} onClose={onClose} />
    </Formik>
  );
};

export default FormikCancelledTranscriptReportConfigFormDialog;

const initialValues = {
  startDate: null,
  endDate: null
};

const validate = values => {
  const errors = {};

  if (values.startDate && values.endDate) {
    if (values.endDate.isBefore(values.startDate)) {
      errors.endDate = "Cannot be earlier than start date";
    }
  }

  return errors;
};
