import React from "react";
import PageHeader from "../../../components/PageHeader";
import { CUSTOMER_USER_MANAGEMENT_PATH } from "../../../constants/route.constant";
import IconSubTitle from "../../../components/IconSubTitle";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import { retrieveInstructorName } from "../../../utils/app.util";

const ManagementPageHeader = props => {
  const { parent } = props;

  return (
    <React.Fragment>
      <PageHeader
        section="Client Management"
        title="Transcript History"
        breadcrumbs={[
          {
            name: "Clients",
            link: CUSTOMER_USER_MANAGEMENT_PATH
          }
        ]}
        subTitle={
          parent && (
            <IconSubTitle
              config={[
                {
                  icon: FaceOutlinedIcon,
                  description: retrieveInstructorName(parent)
                }
              ]}
            />
          )
        }
      />
    </React.Fragment>
  );
};

export default ManagementPageHeader;
