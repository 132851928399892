import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  // inputRoot: {
  //   paddingTop: [0, "!important"],
  //   paddingBottom: [0, "!important"]
  // },
});

export default function AutocompletePlus(props) {
  const { margin, classes, renderInput, InputProps, ...rest } = props;

  const styleClasses = useStyles();

  return (
    <Autocomplete
      {...rest}
      classes={{
        ...classes,
        inputRoot: clsx({
          [styleClasses.inputRoot]: margin === "dense"
        })
      }}
      renderInput={params => {
        params.inputProps = {
          ...params.inputProps,
          autoComplete: "off"
        };

        params.InputProps = {
          ...params.InputProps,
          ...InputProps
        };

        return renderInput({
          ...params,
          margin
        });
      }}
    />
  );
}
