import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import _ from "lodash";
import { EMAIL_TEMPLATE_USAGE_DESC } from "../../../constants/app.constant";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    const truncatedName = _.truncate(entity.subject, {
      length: 30,
      separator: " "
    });

    axios
      .get(`/api/mailTemplates/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch mail template "${truncatedName}"`,
                variant: "error"
              }
            },
            {
              message: `Unable to fetch mail template "${truncatedName}"`,
              variant: "error"
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Mail Template Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Usage</TableCell>
            <TableCell>
              {EMAIL_TEMPLATE_USAGE_DESC[detailedEntity.usageContext]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Subject</TableCell>
            <TableCell>{detailedEntity.subject}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Content</TableCell>
            <TableCell>
              <div
                dangerouslySetInnerHTML={{
                  __html: detailedEntity.content
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
