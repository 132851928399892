import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import localeService from "../../services/locale.service";
import { IntlProvider } from "react-intl";
import { MESSAGES } from "../../constants/locale.constant";
import { PRIVACY_ZH_PATH } from "../../constants/route.constant";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%"
  },
  locale: {
    margin: theme.spacing(6, 0, 1)
  },
  versionDiv: {
    float: "right"
  }
}));

export default function PrivacyZhPage() {
  const classes = useStyles();

  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  return (
    <IntlProvider locale={locale} messages={MESSAGES[locale]}>
      <Grid
        component="main"
        justify="space-evenly"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} >
          <div className={classes.panel}>

          <div  className={classes.versionDiv}>
            <Link to={PRIVACY_ZH_PATH}>
              中文版本
            </Link>
          </div>

          <h2><b>Privacy</b></h2>
            <pre>
            {`
Disclaimer
All information contained in this web page is meant for reference only. Webz Design Company does not warrant or represent that the information provided here is accurate,
complete or up-to-date, and disclaims any liability whatsoever for any loss or damage howsoever arising from or in reliance upon the content of this web page.


Personal Information Collection Statement
We are committed to protect your personal data (the “Data”). This Personal Information Collection Statement is to notify you of certain matter on or before collecting Data from you in compliance with the Personal Data 
(Privacy) Ordinance of the Hong Kong SAR. We limit the collection and use of the Data to the specific information we need to administer our business and provide highest quality services to you.


We may use the Data for one or more of the following purposes:
for the facilities and/or services provided by us or business associates or partners;

for statistics, communication, marketing, promotional, customer relationship management, public relations and/or direct marketing purposes;

for application, creation, operation and administration of accounts and records for customers, members or users;

to handle comments, enquiries and complaints;

to process billing and payment;

for identification and verification purposes;

administering and maintaining our website and diagnosing problems where necessary;

any other purposes as specified in a personal information collection statement when you Data is being collected or any other purposes which you may from time to time agree.

We may share and/or transfer the Data with our third party service providers, agents and contractors for the above purposes for us. We may also disclose the Data when required by law or court order, or as requested by any government 
or law enforcement authorities, or in good faith that disclosure is necessary or advisable to protect the rights or properties of us or other persons.

We intend to use the Data for direct marketing purposes in relation to the Classes of products and Services. However, we may not do so unless we have received your consent for that purpose. In this connection, we may use the following Data:
(a) Name and contact details;

(b) Products and services portfolio information;

(c) Transaction pattern and behaviour; and

(d) Demographic information.

The provision of the Data is voluntary. However, if you elect not to provide the Data to us, we may not be able to supply you with the relevant information or services or to process your request.

Consent will be sought from you for such use of the Data in direct marketing as described above. Please indicate your agreement or objection to such use of the Data in direct marketing as described above before providing the Data to us. 
You may also subsequently write to us by email to webzdesigncompany@gmail.com to opt-out from direct marketing or any other purposes above at any time.

The person to whom requests for access to Data or correction of Data or for information regarding our Privacy Policy should be emailed to our customer service officer at: webzdesigncompany@gmail.com.

In case of any discrepancy between the English and Chinese versions, the English version shall prevail.
`}
            </pre>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
