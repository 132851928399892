import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";

const ManagementPageHeader = props => {
  const { onCreate } = props;

  return (
    <PageHeader
      section="Administration"
      title="Closed Business Days"
      right={
        <Button color="secondary" variant="contained" onClick={onCreate}>
          Add Day
        </Button>
      }
    />
  );
};

export default ManagementPageHeader;
