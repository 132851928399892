import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validationSchema
} from "./AttributeFormDialog";

const EditForm = props => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/notifications/${entity.id}`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to notification "${entity.name}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch notification "${entity.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <AttributeFormDialog
      {...rest}
      title="Edit Notification"
      open={open}
      onClose={onClose}
      loading={loading}
    />
  );
};

const FormikEditForm = props => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating notification "${values.name}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/notifications/${values.id}`, createParams(values))
      .then(() => {
        notify(`Notification "${values.name}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for notification "${values.name}"`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update notification`,
              variant: "error"
            }
          },
          {
            message: `Unable to update notification "${values.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = receivedValues => {
  receivedValues.name || (receivedValues.name = "");
  receivedValues.content || (receivedValues.content = "");
  receivedValues.externalLink || (receivedValues.externalLink = "");
};
