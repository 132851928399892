import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";

export default function(props) {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing package plan "${entity.packagePlan.name}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .delete(`/api/customerUsers/packagePlans/${entity.id}`)
      .then(() => {
        notify(`Package plan "${entity.packagePlan.name}" removed`, "success");

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to remove package plan "${entity.packagePlan.name}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to remove package plan "${entity.packagePlan.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Package Plan?"
      message={
        <React.Fragment>
          Package plan <b>{entity.packagePlan.name}</b> is going to be deleted.
          All class records related to this package plan will also be removed.
          You will not be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
}
