import { useSelector } from "react-redux";
import {
  ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
  ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
  ACCESS_CONTROL_ENTRY_NOTIFICATION,
  ACCESS_CONTROL_ENTRY_OFFERING,
  ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
  ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
  ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
  ACCESS_CONTROL_ENTRY_STAFF_USER,
  ACCESS_CONTROL_ENTRY_REPORT,
  ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
  ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
  ACCESS_CONTROL_LEVEL_YES,
  ACCESS_CONTROL_LEVEL_READ,
  ACCESS_CONTROL_LEVEL_UNRESTRICTED,
  ACCESS_CONTROL_LEVEL_UPDATE,
  ACCESS_CONTROL_ENTRY_STORE_BRANCH,
  ACCESS_CONTROL_ENTRY_ENROLL_CLIENT,
  ACCESS_CONTROL_ENTRY_PROMOTION
} from "../constants/app.constant";

export function useAccessControl() {
  const { accessControlPoints } = useSelector(state => state.session.data.personalSettings);

  const isAccessGranted = accessControls => {
    let accessDenied = false;

    if (accessControls) {
      for (let i = 0; i < accessControls.length; i++) {
        if (accessControlPoints[accessControls[i].entry] == null || accessControlPoints[accessControls[i].entry] < accessControls[i].level) {
          accessDenied = true;
          break;
        }
      }
    }

    return !accessDenied;
  };

  const isReadStaffUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STAFF_USER,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateStaffUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STAFF_USER,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditStaffUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STAFF_USER,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteStaffUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STAFF_USER,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadOfferingCategoryAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateOfferingCategoryAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditOfferingCategoryAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteOfferingCategoryAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadCustomerUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateCustomerUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditCustomerUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteCustomerUserAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadOfferingAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateOfferingAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditOfferingAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteOfferingAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadOfferingScheduleAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateOfferingScheduleAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditOfferingScheduleAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteOfferingScheduleAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadPackagePlanAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreatePackagePlanAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditPackagePlanAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeletePackagePlanAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadNotificationAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_NOTIFICATION,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateNotificationAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_NOTIFICATION,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditNotificationAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_NOTIFICATION,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteNotificationAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_NOTIFICATION,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReadMailTemplateAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateMailTemplateAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditMailTemplateAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteMailTemplateAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };
  const isReadStoreBranchAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STORE_BRANCH,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ]);
  };

  const isCreateStoreBranchAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STORE_BRANCH,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isEditStoreBranchAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STORE_BRANCH,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ]);
  };

  const isDeleteStoreBranchAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_STORE_BRANCH,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ]);
  };

  const isReportAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_REPORT,
        level: ACCESS_CONTROL_LEVEL_YES
      }
    ]);
  };

  const isSystemConfigurationAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
        level: ACCESS_CONTROL_LEVEL_YES
      }
    ]);
  };

  const isClosedBusinessDayAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
        level: ACCESS_CONTROL_LEVEL_YES
      }
    ]);
  };

  const isEnrollClientAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_ENROLL_CLIENT,
        level: ACCESS_CONTROL_LEVEL_YES
      }
    ]);
  };

  const isReadPromotionAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PROMOTION,
        level: ACCESS_CONTROL_LEVEL_READ
      }
    ])
  }

  const isCreatePromotionAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PROMOTION,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ])
  }

  const isEditPromotionAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PROMOTION,
        level: ACCESS_CONTROL_LEVEL_UPDATE
      }
    ])
  }

  const isDeletePromotionAllowed = () => {
    return isAccessGranted([
      {
        entry: ACCESS_CONTROL_ENTRY_PROMOTION,
        level: ACCESS_CONTROL_LEVEL_UNRESTRICTED
      }
    ])
  }

  return {
    isAccessGranted,
    isReadStaffUserAllowed,
    isCreateStaffUserAllowed,
    isEditStaffUserAllowed,
    isDeleteStaffUserAllowed,
    isReadOfferingCategoryAllowed,
    isCreateOfferingCategoryAllowed,
    isEditOfferingCategoryAllowed,
    isDeleteOfferingCategoryAllowed,
    isReadCustomerUserAllowed,
    isCreateCustomerUserAllowed,
    isEditCustomerUserAllowed,
    isDeleteCustomerUserAllowed,
    isReadOfferingAllowed,
    isCreateOfferingAllowed,
    isEditOfferingAllowed,
    isDeleteOfferingAllowed,
    isReadOfferingScheduleAllowed,
    isCreateOfferingScheduleAllowed,
    isEditOfferingScheduleAllowed,
    isDeleteOfferingScheduleAllowed,
    isReadPackagePlanAllowed,
    isCreatePackagePlanAllowed,
    isEditPackagePlanAllowed,
    isDeletePackagePlanAllowed,
    isReadNotificationAllowed,
    isCreateNotificationAllowed,
    isEditNotificationAllowed,
    isDeleteNotificationAllowed,
    isReadMailTemplateAllowed,
    isCreateMailTemplateAllowed,
    isEditMailTemplateAllowed,
    isDeleteMailTemplateAllowed,
    isReadStoreBranchAllowed,
    isEditStoreBranchAllowed,
    isCreateStoreBranchAllowed,
    isDeleteStoreBranchAllowed,
    isReportAllowed,
    isSystemConfigurationAllowed,
    isClosedBusinessDayAllowed,
    isEnrollClientAllowed,
    isReadPromotionAllowed,
    isCreatePromotionAllowed,
    isEditPromotionAllowed,
    isDeletePromotionAllowed
  };
}
