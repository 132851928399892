import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import TopBar from "./components/TopBar";
import NavBar from "./components/NavBar";

const navBarWidth = 256;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden"
  },
  container: {
    height: "100%",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -navBarWidth
  },
  containerShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  content: {
    height: "100%",
    flex: "1 1 auto",
    overflowY: "auto",
    backgroundColor: theme.palette.background.default
  },
  topBar: {
    position: "relative"
  },
  navBar: {
    flex: "0 0 auto",
    width: navBarWidth
  }
}));

const SideLayout = props => {
  const { scrollWindowRef } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const [navOpen, setNavOpen] = useState(!isMdDown);
  const toggleNavOpen = () => {
    setNavOpen(!navOpen);
  };

  return (
    <div className={classes.root}>
      <NavBar
        className={classes.navBar}
        navOpen={navOpen}
        toggleNavOpen={toggleNavOpen}
      />
      <div
        className={clsx(classes.container, {
          [classes.containerShift]: navOpen || isMdDown
        })}
      >
        <TopBar className={classes.topBar} toggleNavOpen={toggleNavOpen} />

        <main className={classes.content} ref={scrollWindowRef}>
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default SideLayout;
