import React from "react";
import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import _ from "lodash";

export default function(props) {
  const { name, component: Component, ...rest } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting
  } = useFormikContext();

  const Field = Component || TextField;

  return (
    <Field
      name={name}
      value={_.get(values, name)}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={isSubmitting}
      error={Boolean(_.get(touched, name) && _.get(errors, name))}
      helperText={_.get(touched, name) && _.get(errors, name)}
      {...rest}
    />
  );
}
