import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { useApiNotification } from "../../../hooks/notification.hook";
import axios from "axios";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import {
  PACKAGE_PLAN_STATUS_VALUE_DISPLAY_MAPPING,
  PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING
} from "../../../constants/app.constant";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/customerUsers/packagePlans/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to fetch package plan "${entity.packagePlan.name}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch package plan "${entity.packagePlan.name}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Package Plan Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Package Plan</TableCell>
            <TableCell>
              {detailedEntity.packagePlan && detailedEntity.packagePlan.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>
              {detailedEntity.packagePlan &&
                PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING[
                  detailedEntity.packagePlan.type
                ]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Effective Date</TableCell>
            <TableCell>
              {detailedEntity.effectiveDate &&
                moment(detailedEntity.effectiveDate).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Expiration Date</TableCell>
            <TableCell>
              {detailedEntity.expirationDate &&
                moment(detailedEntity.expirationDate).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              {detailedEntity.status &&
                PACKAGE_PLAN_STATUS_VALUE_DISPLAY_MAPPING[entity.status]}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
