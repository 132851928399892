export function isSameLanguage(locale1, locale2) {
  if (!locale1 || !locale2) {
    return false;
  }

  let language1 = locale1.split(/[_-]/)[0];
  let language2 = locale2.split(/[_-]/)[0];

  return language1 === language2;
}

export function formatToHyphen(locale) {
  return locale.split(/[_-]/).join("-");
}
