import Grid from "@material-ui/core/Grid";
import React, { useCallback } from "react";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import axios from "axios";
import LoadableSelect from "../../../components/inputs/LoadableSelect";
import AutoCompleteOffTextField from "../../../components/inputs/AutoCompleteOffTextField";
import { retrieveInstructorName } from "../../../utils/app.util";

const FilterForm = props => {
  const { loading, onValueChange, onCurrentDayChange, values } = props;

  const offeringCategoryRequest = useCallback(() => {
    return axios.get("/api/offeringCategories/variants/references");
  }, []);

  const instructorRequest = useCallback(() => {
    return axios.get("/api/staffUsers/variants/references", {
      params: { instructor: true }
    });
  }, []);

  const storeBranchRequest = useCallback(() => {
    return axios.get("/api/storeBranches/variants/references");
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            label="Current Day"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
            margin="dense"
            InputAdornmentProps={{ position: "start" }}
            KeyboardButtonProps={{ size: "small" }}
            TextFieldComponent={AutoCompleteOffTextField}
            value={values.currentDay}
            onChange={onCurrentDayChange}
            disabled={loading}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <LoadableSelect
            fullWidth
            label="Class Category"
            margin="dense"
            variant="outlined"
            request={offeringCategoryRequest}
            getOptionLabel={option =>
              option && option.name ? option.name : ""
            }
            value={values.offeringCategory}
            onChange={(event, value) => {
              onValueChange("offeringCategory", value);
            }}
            disabled={loading}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <LoadableSelect
            fullWidth
            label="Instructor"
            variant="outlined"
            margin="dense"
            request={instructorRequest}
            getOptionLabel={option => retrieveInstructorName(option)}
            value={values.instructor}
            onChange={(event, value) => {
              onValueChange("instructor", value);
            }}
            disabled={loading}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <LoadableSelect
            fullWidth
            label="Branch"
            variant="outlined"
            margin="dense"
            request={storeBranchRequest}
            getOptionLabel={option =>
              option && option.name ? option.name : ""
            }
            value={values.storeBranch}
            onChange={(event, value) => {
              onValueChange("storeBranch", value);
            }}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FilterForm;
