import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import { useApiNotification, useNotification } from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import { retrieveInstructorName } from "../../../utils/app.util";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikInstructorSelect from "../../../components/inputs/FormikInstructorSelect";
import * as Yup from "yup";
import FormikKeyboardTimePicker from "../../../components/inputs/FormikKeyboardTimePicker";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";

const EditForm = props => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    const instructorName = retrieveInstructorName(entity.instructor);

    axios
      .get(`/api/offeringSchedules/${entity.id}`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch(error => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to class schedule for instructor "${instructorName}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch class schedule for instructor "${instructorName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <FormikFormDialog {...rest} title="Edit Class Schedule" open={open} onClose={onClose} loading={loading}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikInstructorSelect required name="instructor" label="Instructor" fullWidth margin="dense" variant="outlined" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikStoreBranchSelect fullWidth name="storeBranch" label="Store Branch" margin="dense" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardDatePicker required name="startDate" label="Start Date" />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardDatePicker required disablePast name="endDate" label="End Date" />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardTimePicker required name="startTime" label="Start Time" />
          </Grid>
          <Grid item xs={6}>
            <FormikKeyboardTimePicker required name="endTime" label="End Time" />
          </Grid>
          {/*<Grid item md={6} xs={12}>*/}
          {/*  <FormikTextField*/}
          {/*    name="capacity"*/}
          {/*    label="Total Capacity"*/}
          {/*    fullWidth*/}
          {/*    margin="dense"*/}
          {/*    variant="outlined"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item md={6} xs={12}>
            <FormikTextField name="onlineCapacity" label="Online Capacity" fullWidth margin="dense" variant="outlined" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField name="waitListCapacity" label="Wait List Capacity" fullWidth margin="dense" variant="outlined" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="publicAvailable"
              label="Show to Public"
              description="Enable this will allow clients to view sessions within this class schedule"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="figureAvailable"
              label="Show Enrollment Figures"
              description="Enable this will allow clients to view enrollment figures of the schedule"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
};

const FormikEditForm = props => {
  const { entity, onOpen, onClose, onSuccess, onBefore, onAfter, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const instructorName = retrieveInstructorName(values.instructor);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating class schedule for instructor "${instructorName}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/offeringSchedules/${values.id}`, createParams(values))
      .then(() => {
        notify(`Class schedule for instructor "${instructorName}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for scheduling data`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update class schedule`,
              variant: "error"
            }
          },
          {
            message: `Unable to update class schedule for instructor "${instructorName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validate={validate}>
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const validationSchema = Yup.object().shape({
  instructor: Yup.object()
    .nullable(true)
    .required("Required"),
  startDate: Yup.object()
    .nullable(true)
    .required("Required"),
  endDate: Yup.object()
    .nullable(true)
    .required("Required"),
  startTime: Yup.object()
    .nullable(true)
    .required("Required"),
  endTime: Yup.object()
    .nullable(true)
    .required("Required"),
  // capacity: Yup.number()
  //   .typeError("Must be an integer")
  //   .integer("Can only be integers")
  //   .min(0, "Can not be smaller than 0"),
  onlineCapacity: Yup.number()
    .typeError("Must be an integer")
    .integer("Can only be integers")
    .min(0, "Can not be smaller than 0"),
  // .max(Yup.ref("capacity"), "Cannot exceed the capacity"),
  waitListCapacity: Yup.number()
    .typeError("Must be an integer")
    .integer("Can only be integers")
    .min(0, "Can not be smaller than 0")
});

const validate = values => {
  const errors = {};

  if (values.startDate && values.endDate) {
    if (values.endDate.isSameOrBefore(values.startDate)) {
      errors.endDate = "Cannot be earlier than or equal to start date";
    }
  }

  if (values.startTime && values.endTime) {
    if (values.endTime.isSameOrBefore(values.startTime)) {
      errors.endTime = "Cannot be earlier than or equal to start time";
    }
  }

  return errors;
};

const initialValues = {
  id: null,
  instructor: null,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  // capacity: "",
  onlineCapacity: "",
  waitListCapacity: "",
  publicAvailable: false,
  figureAvailable: true,
  storeBranch: null
};

const createParams = values => {
  const params = new URLSearchParams();

  values.instructor && params.append("instructor", values.instructor.id);

  values.startDate && params.append("startDate", values.startDate.format("YYYY-MM-DD"));
  values.endDate && params.append("endDate", values.endDate.format("YYYY-MM-DD"));

  values.startTime && params.append("startTime", values.startTime.format("HH:mm"));
  values.endTime && params.append("endTime", values.endTime.format("HH:mm"));

  // values.capacity && params.append("capacity", values.capacity);
  values.onlineCapacity && params.append("onlineCapacity", values.onlineCapacity);
  values.waitListCapacity && params.append("waitListCapacity", values.waitListCapacity);

  values.publicAvailable && params.append("publicAvailable", values.publicAvailable);
  values.figureAvailable && params.append("figureAvailable", values.figureAvailable);
  values.storeBranch && params.append("storeBranch", values.storeBranch.id);

  return params;
};

const normalizeReceivedValues = receivedValues => {
  receivedValues.instructor || (receivedValues.instructor = null);
  receivedValues.startDate || (receivedValues.startDate = null);
  receivedValues.endDate || (receivedValues.endDate = null);
  receivedValues.startTime || (receivedValues.startTime = null);
  receivedValues.endTime || (receivedValues.endTime = null);
  // receivedValues.capacity || (receivedValues.capacity = "");
  receivedValues.onlineCapacity || (receivedValues.onlineCapacity = "");
  receivedValues.waitListCapacity || (receivedValues.waitListCapacity = "");
  receivedValues.publicAvailable || (receivedValues.publicAvailable = false);
  receivedValues.figureAvailable || (receivedValues.figureAvailable = false);

  receivedValues.storeBranch || (receivedValues.storeBranch = null);

  receivedValues.startDate && (receivedValues.startDate = moment(receivedValues.startDate, "YYYY-MM-DD"));
  receivedValues.endDate && (receivedValues.endDate = moment(receivedValues.endDate, "YYYY-MM-DD"));

  receivedValues.startTime && (receivedValues.startTime = moment(receivedValues.startTime, "HH:mm"));
  receivedValues.endTime && (receivedValues.endTime = moment(receivedValues.endTime, "HH:mm"));
};
