import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import WizardStepCard from "./WizardStepCard";
import CustomerUserSearchForm from "./CustomerUserSearchForm";
import SelectPurchasedPackagePlanForm from "./SelectPurchasedPackagePlanForm";
import CustomerUserSelectionForm from "./CustomerUserSelectionForm";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FormDialog from "../../../components/dialogs/FormDialog";

const useStyles = makeStyles({
  form: {
    width: 836
  }
});

export default function(props) {
  const {
    open,
    onClose,
    title,
    loading,
    onSearchSubmit,
    onCustomerUserSelect,
    candidates,
    selectedCustomerUser,
    purchasedPackagePlans,
    onPurchasedPackagePlanSelect,
    selectedPurchasedPackagePlan,
    onSubmit,
    onReset,
    dirty,
    isValid,
    isSubmitting,
    searchText,
    onSearchTextChange,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <FormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
      paperClassName={classes.form}
      onSubmit={onSubmit}
      onReset={onReset}
      submitButtonDisabled={!isValid|| isSubmitting}
      resetButtonDisabled={!isValid || isSubmitting}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <WizardStepCard order={1} title="Search Client">
          <CustomerUserSearchForm
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            onSubmit={onSearchSubmit}
          />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        <WizardStepCard order={2} title="Select Client">
          <CustomerUserSelectionForm
            candidates={candidates}
            onSelect={onCustomerUserSelect}
            selected={selectedCustomerUser}
          />
        </WizardStepCard>
        <Divider orientation="horizontal" /> */}
        <WizardStepCard order={1} title="Change Package Plan">
          <SelectPurchasedPackagePlanForm
            purchasedPackagePlans={purchasedPackagePlans}
            onSelect={onPurchasedPackagePlanSelect}
            selected={selectedPurchasedPackagePlan}
          />
        </WizardStepCard>
      </MuiPickersUtilsProvider>
    </FormDialog>
  );
}
