import { DEFAULT_LOCALE, MESSAGES } from "../constants/locale.constant";
import { formatToHyphen } from "../utils/locale.util";

class localeService {
  detectLocaleIfPossible = () => {
    let locale =
      (navigator.languages && navigator.languages[0]) || navigator.language;

    return formatToHyphen(
      locale && this.isLocaleSupported(locale) ? locale : DEFAULT_LOCALE
    );
  };

  isLocaleSupported(locale) {
    return Object.keys(MESSAGES).includes(locale);
  }
}

let instance = new localeService();

export default instance;
