import React, { useCallback } from "react";
import axios from "axios";
import FormikLoadableSelect from "./FormikLoadableSelect";

const FormikStoreBranchSelect = props => {
  const request = useCallback(() => {
    return axios.get("/api/storeBranches/variants/references");
  }, []);

  return (
    <FormikLoadableSelect
      {...props}
      request={request}
      getOptionLabel={option => (option && option.name ? option.name : "")}
    />
  );
};

export default FormikStoreBranchSelect;
