import { combineReducers } from "redux";
import personalSettings from "./personalSettings.reducer";
import sessionStatus from "./sessionStatus.reducer";

const sessionData = combineReducers({ personalSettings });

const session = combineReducers({
  data: sessionData,
  status: sessionStatus
});

export default session;
