import MuiTableHead from "./MuiTableHead";
import MuiTableCell from "./MuiTableCell";
import MuiInputLabel from "./MuiInputLabel";
import MuiOutlinedInput from "./MuiOutlinedInput";

export default {
  MuiTableHead,
  MuiTableCell,
  MuiInputLabel,
  MuiOutlinedInput
};
