import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import localeService from "../../services/locale.service";
import { IntlProvider } from "react-intl";
import { MESSAGES } from "../../constants/locale.constant";
import { TERMS_AND_CONDITIONS_ZH_PATH } from "../../constants/route.constant";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%"
  },
  locale: {
    margin: theme.spacing(6, 0, 1)
  },
  versionDiv: {
    float: "right"
  }
}));

export default function TermsAndConditionsEnPage() {
  const classes = useStyles();

  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  return (
    <IntlProvider locale={locale} messages={MESSAGES[locale]}>
      <Grid
        component="main"
        justify="space-evenly"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} >
          <div className={classes.panel}>
            
          <div  className={classes.versionDiv}>
            <Link to={TERMS_AND_CONDITIONS_ZH_PATH}>
              中文版本
            </Link>
          </div>

          <h2><b>Terms and Conditions</b></h2>
            <pre>
{`
The Member acknowledges that Webz Design Company operates under rules and regulations established for the safety and protection of its members, and agrees to be bound by such rules and regulations, as well as the rules 
and regulations subsequently approved and posted or otherwise published by Webz Design Company. Such rules and regulations in effect from time to time are incorporated into this Agreement by reference. Facilities, equipment, hours, service,
regulations and policies are subject to change from time to time, without prior notice, in the sole and absolute discretion of Webz Design Company. Member agrees to accept such reasonable change(s) as a condition of membership.

 
The Member recognizes that:
a) Under no circumstances shall Member move exercise equipment or use the equipment in any manner not authorized by Webz Design Company;

b) All equipment shall be wiped down by Member after each use, with the supplies provided by Webz Design Company;

c) This membership is for the Member only and the Membership shall not give access to another individual. Should there be any violation of this rule by the Member, Webz Design Company reserve the right and discretion to immediately terminate 
the Membership and demand for monetary penalty or equivalent to membership fee for 1-month membership in accordance with the Price List in effect at the time being , whichever is higher, against the Member;

d) The Member shall not misuse the equipment and only use the equipment in accordance with the guidelines posted in the Facility;

e) The Member shall be at least eighteen (18) years of age, unless otherwise authorized by Webz Design Company;

f) The Member shall conduct themselves in a quiet, well-mannered fashion so as not to cause any disturbance which may interfere with the use and enjoyment of the Facility by any other member. Profanity or indecent language and/or behavior 
will not be tolerated. Any conduct deemed by Webz Design Company, in its sole discretion, to be offensive, potentially harmful, dangerous or abusive will not be tolerated, 
and shall be grounds for termination of Member’s membership by Webz Design Company;

g) Webz Design Company shall not be responsible for any lost or stolen items;

h) The Member should not occupy any equipment for an extended period of time and should allow others waiting to use such equipment to work into their rotation; and

i) The climate of the Facility is controlled by Webz Design Company and is set to provide the optimum exercise environment for the majority of its members. The Member shall not change or seek to change any environmental controls and
shall never prop open any doors or windows to the Facility for any purpose.

 

Alcohol, drugs (including steroids), and smoking are prohibited within the Facility. The Member agrees not to use the Facility or engage in any activity at Webz Design Company while under the influence of drugs, alcohol, or medication that
may impair Member’s ability to operate the equipment. No weapons of any kind are allowed. No photography, videotaping, filming or audio recording is permitted within the Facility without the express written consent of Webz Design Company’s
management. Webz Design Company reserves the right, in its sole discretion, to limit the consumption of food or beverages, or to the use of outside equipment within the Facility.
Gambling or gaming is prohibited within the Facility or on the premises.


Webz Design Company requires that Members wear appropriate clothing and footwear while in the Facility. Appropriate clothing includes gym shorts, T-shirts, jogging suits, aerobic wear and sweat outfits. 
Street clothing and jeans are not considered appropriate clothing. Street shoes and black-soled shoes are prohibited within the Facility. 
The Member agrees to have a cloth towel during workouts to protect and clean the equipment after Member’s use.

 
Webz Design Company may update their policies and practices in relation to the use of the Facility from time to time.
They will notify the Members about the significant changes as made to its policies or practices by placing a notice in respect of such changes on its website or in its Facility.


The Member agrees and acknowledges that his use and enjoyment of the Facility and/or services provided by Webz Design Company under this Agreement may be limited by problem, difficulties and/or incapability of himself/herself and 
that the Member is still obliged to make full payment of the Membership Fees and other charges under this Agreement and Webz Design Company shall not in any event refund in whole or in part any of the fees payable 
by the Member under this Agreement.


The Member may suspend their Membership at HK$_________ per month and giving thirty (30) days prior written notice to Webz Design Company (completion of relevant application form is required). 
The minimum suspension period is one (1) month and the maximum suspension period is twelve (12) months. During period of suspension, the Member cannot access or use the Facility and/or services provided by Webz Design Company.
`}
            </pre>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
