import React, { useCallback, useContext, useEffect, useState } from "react";
import FilterForm from "./components/FilterForm";
import Frame from "../../components/Frame";
import { makeStyles } from "@material-ui/core";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import ScheduleSessionTable from "./components/ScheduleSessionTable";
import EditFormDialog from "./components/EditFormDialog";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import ContentScrollWindowContext from "../../layouts/ContentScrollWindowContext";
import InfiniteScrollLoader from "./components/InfiniteScrollLoader";
import clsx from "clsx";
import ErrorFilterResultPlaceholder from "../../components/filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "../../components/filters/EmptyFilterResultPlaceholder";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { OFFERING_SCHEDULE_SESSION_LIST_PATH } from "../../constants/route.constant";
import {
  UPCOMING_OFFERING_SCHEDULE_SESSION_DAY_LIMIT_COUNT,
  UPCOMING_OFFERING_SCHEDULE_SESSION_DAY_SIZE,
} from "../../config/app.config";
import { dateComparator } from "../../utils/app.util";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  emptyFilterResult: {
    flex: "1 1 auto",
  },
  errorFilterResult: {
    flex: "1 1 auto",
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: theme.spacing(3),
  },
}));

const daySize = UPCOMING_OFFERING_SCHEDULE_SESSION_DAY_SIZE;
const dayLimitCount = UPCOMING_OFFERING_SCHEDULE_SESSION_DAY_LIMIT_COUNT;

const UpcomingOfferingScheduleSessionList = () => {
  const classes = useStyles();

  /*
  Filter
   */

  const [filters, setFilters] = React.useState({
    startDay: moment().startOf("day"),
  });

  const [editFormDialogState, setEditFormDialogState] = useState({
    open: false,
  });

  const handleEditFormDialogOpen = React.useCallback(() => {
    setEditFormDialogState((prevState) => ({
      ...prevState,
      open: true,
    }));
  }, []);

  const handleEditFormDialogClose = React.useCallback(() => {
    setEditFormDialogState((prevState) => ({
      ...prevState,
      open: false,
    }));
  }, []);

  const [editing, setEditing] = useState(false);

  const editFormDialog = (
    <EditFormDialog
      {...editFormDialogState}
      onOpen={handleEditFormDialogOpen}
      onClose={handleEditFormDialogClose}
      onSuccess={() => {
        setEditFormDialogState((prevState) => ({
          ...prevState,
          entity: null,
        }));
      }}
      onBefore={() => {
        setEditing(true);
      }}
      onAfter={() => {
        setEditing(false);
      }}
    />
  );

  const handleEdit = (entity) => {
    setEditFormDialogState((prevState) => ({
      ...prevState,
      entity: entity,
      open: true,
    }));
  };

  const handleFilterSubmit = (values) => {
    if (!loadingMore) {
      setLoadingMore(true);
      setSessionGroupByDay({});
      setFilters({ ...values, startDay: moment().startOf("day") });
    }
  };

  /*
  Data to display
   */

  const [sessionGroupByDay, setSessionGroupByDay] = useState({});

  /*
  Status for loading listing table
   */

  const [loadingMore, setLoadingMore] = useState(true);
  const [loadingMoreError, setLoadingMoreError] = useState(false);

  /*
  Infinite loader
   */

  const dayLimit = moment()
    .startOf("day")
    .add(dayLimitCount, "days");

  const loadMore = useCallback(() => {
    if (!loadingMore) {
      setLoadingMore(true);

      setFilters((prevState) => ({
        ...prevState,
        startDay: moment(prevState.startDay).add(daySize, "days"),
      }));
    }
  }, [loadingMore]);

  const hasMore = moment(filters.startDay).isSameOrBefore(dayLimit);

  useEffect(() => {
    if (!hasMore) {
      setLoadingMore(false);
      return;
    }

    let active = true;

    setLoadingMore(true);

    const params = {
      ...filters,
      daySize: daySize,
    };

    axios
      .post("/api/offeringScheduleSessions/actions/query", createParams(params))
      .then((response) => {
        if (active) {
          const data = response.data || {};

          setSessionGroupByDay((prevState) => ({
            ...prevState,
            ...data,
          }));

          setLoadingMoreError(false);
        }
      })
      .catch(() => {
        active && setLoadingMoreError(true);
      })
      .finally(() => {
        active && setLoadingMore(false);
      });

    return () => {
      active = false;
    };
  }, [filters, hasMore]);

  const contentScrollWindowRef = useContext(ContentScrollWindowContext);

  const days = Object.keys(sessionGroupByDay).sort(dateComparator);

  return (
    <Frame className={classes.root}>
      <PageHeader section="Overview" title="Home" />
      <FilterForm loading={loadingMore} onSubmit={handleFilterSubmit} />
      {loadingMoreError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : (!days || days.length === 0) && !hasMore ? (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      ) : (
        <InfiniteScroll
          className={clsx({ [classes.progress]: !days || days.length === 0 })}
          hasMore={hasMore}
          loader={<InfiniteScrollLoader key="infinite-scroll-loader" />}
          loadMore={loadMore}
          useWindow={false}
          threshold={40}
          getScrollParent={() => contentScrollWindowRef.current}
        >
          {days.map((day) => (
            <ScheduleSessionTable
              key={day}
              day={day}
              sessions={sessionGroupByDay[day]}
              editFormDialog={EditFormDialog}
            />
          ))}
        </InfiniteScroll>
      )}
      {days && days.length > 0 && !hasMore && (
        <div className={classes.scrollBottom}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            No more schedules found in recent {dayLimitCount} days.
          </Typography>
          <Link to={OFFERING_SCHEDULE_SESSION_LIST_PATH}>
            <Button color="secondary" size="small" variant="outlined">
              Check More
            </Button>
          </Link>
        </div>
      )}
    </Frame>
  );
};

export default UpcomingOfferingScheduleSessionList;

const createParams = (values) => {
  const params = new URLSearchParams();

  values.startDay &&
    params.append("startDay", values.startDay.format("YYYY-MM-DD"));

  values.daySize && params.append("daySize", values.daySize);

  values.offeringCategories &&
    values.offeringCategories.forEach((offeringCategory, index) => {
      params.append(`offeringCategories[${index}]`, offeringCategory.id);
    });

  values.instructors &&
    values.instructors.forEach((instructor, index) => {
      params.append(`instructors[${index}]`, instructor.id);
    });

  return params;
};
