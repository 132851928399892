import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import localeService from "../../services/locale.service";
import { IntlProvider } from "react-intl";
import { MESSAGES } from "../../constants/locale.constant";
import Link from "@material-ui/core/Link";
import { LOGIN_PATH } from "../../constants/route.constant";
import { FormattedMessage } from "react-intl";
import { GridList } from "@material-ui/core";
import { Button, Input } from "@material-ui/core";
import Copyright from "../LoginPage/components/Copyright";
import LocaleLink from "../LoginPage/components/LocaleLink";
import Box from "@material-ui/core/Box";
import FormikTextField from "../../components/inputs/FormikTextField";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import axios from "axios";
import qs from "qs";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockIcon from "@material-ui/icons/Lock";
import PasscodeIcon from "@material-ui/icons/VpnKey";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EmailDialog from "pages/CustomerUserManagement/components/EmailDialog";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper
  },
  image: {
    backgroundImage: "url(/static/images/login/bg.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  panel: {
    margin: theme.spacing(8, 7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  login: {
    maxWidth: theme.spacing(60)
  },
  logo: {
    width: 200,
    marginBottom: theme.spacing(6)
  },
  locale: {
    margin: theme.spacing(6, 0, 1)
  },
  button: {
    marginTop: 8,
    height: 50
  },
  noDisplay: {
    display: "none"
  },
  info: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center'
  }
}));



export default function ForgetPasswordPage() {
  const classes = useStyles();

  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [resetForm, setResetForm] = useState(false);
  const [error, setError] = useState('');
  const [passcodeError, setPasscodeError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState('');
  const [info, setInfo] = useState('');

  const handleEmailSubmit = values => {
    if (error != '')
      return;
    setIsSubmitting(true);

    const params = new URLSearchParams();

    params.append("email", inputEmail);

    axios
      .post(
        `/public/forgetPassword/verifyMail`,params)
      .then(response => {
        if (response.data == false)
          setError(<FormattedMessage id="forgetpassword.email.invalid" />);
        else {
          setResetForm(true);
          setPasscode('');
          setInfo(<FormattedMessage id="forgetpassword.info.sent" />);
        }
      })
      .catch(error => {
        setError(<FormattedMessage id="forgetpassword.email.invalid" />);
      })
    .finally(() => {
      setIsSubmitting(false);
    });

  };

  const handleNewPasswordSubmit = values => {
    if (passcodeError != '' || passwordError != '' || confirmedPasswordError != '')
      return;

    setIsSubmitting(true);
    setInfo('');

    const params = new URLSearchParams();

    params.append("email", inputEmail);
    params.append("passCode", passcode);
    params.append("password", password);
    params.append("confirmedPassword", confirmedPassword);

    axios
      .post(
        `/public/forgetPassword/resetPassword`, params)
      .then(response => {
        setInfo(<FormattedMessage id="forgetpassword.info.success" />);
      })
      .catch(error => {
        setPasscodeError(<FormattedMessage id="forgetpassword.passcode.invalid" />);
      })
    .finally(() => {
      setIsSubmitting(false);
    });

  };

  return (
    <IntlProvider locale={locale} messages={MESSAGES[locale]}>
      <Grid
        container
        component="main"
        className={classes.root}
        justify="space-evenly"
      >
        <Hidden smDown>
          <Grid xs sm md item className={classes.image} />
        </Hidden>

        {!resetForm ? (
          <Grid item xs={12} sm={12} md={6} className={classes.login}>
            <div className={classes.panel}>
              <img
                className={classes.logo}
                src="/static/images/logos/logo.png"
                alt="logo"
              />
              <Grid className={classes.info}>{info}</Grid>

              <br></br>

              <TextField
                className={classes.field}
                id="email"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                error={error}
                label={<FormattedMessage id="forgetpassword.textfield.email" />}
                helperText={<span>{error}</span>}
                values={inputEmail}
                onChange={(e) => {
                  setInputEmail(e.target.value);
                  if (e.target.value != '') {
                    var email = {
                      email: e.target.value
                    }
                    yupObjectVerify
                      .validate(email)
                      .then(function(value) {
                        setError('');
                      })
                      .catch(function(err) {
                        setError(<FormattedMessage id="forgetpassword.email.format" />);
                      });
                  } else {
                    setError('');
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon color="action" />
                    </InputAdornment>
                  )
                }}
              />

              <br></br>

              <Box position="relative">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleEmailSubmit}
                  disabled={isSubmitting || error != '' || inputEmail == ''}
                >
                  {!isSubmitting ? (
                    <FormattedMessage id="forgetpassword.button.send" />
                  ) : (
                      <FormattedMessage id="forgetpassword.button.send.loading" />
                    )}
                </Button>
              </Box>

              <Grid container>
                <Grid item xs>
                  <Link href={LOGIN_PATH} variant="body1">
                    <FormattedMessage id="forgetpassword.back.login.page" />
                  </Link>
                </Grid>
              </Grid>

              <Grid container direction="column">
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  spacing={1}
                  className={classes.locale}
                >
                  <Grid item>
                    <LocaleLink to="en" current={locale} handleChange={setLocale}>
                      English
                  </LocaleLink>
                  </Grid>
                  <Grid item>
                    <LocaleLink
                      to="zh-HK"
                      current={locale}
                      handleChange={setLocale}
                    >
                      繁體中文
                  </LocaleLink>
                  </Grid>
                </Grid>
                <Grid item>
                  <Copyright />
                </Grid>
              </Grid>
            </div>
          </Grid>

        ) : (
            <Grid item xs={12} sm={12} md={6} className={classes.login}>
              <div className={classes.panel}>
                <img
                  className={classes.logo}
                  src="/static/images/logos/logo.png"
                  alt="logo"
                />
                <Grid className={classes.info}>{info}</Grid>

                <br></br>

                <TextField
                  className={classes.noDisplay}
                  id="email"
                  name="email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  error={error}
                  label={<FormattedMessage id="forgetpassword.textfield.email" />}
                  helperText={<span>{error}</span>}
                  values={inputEmail}
                  onChange={(e) => {
                    setInputEmail(e.target.value);
                    if (e.target.value != '') {
                      var email = {
                        email: e.target.value
                      }
                      yupObjectVerify
                        .validate(email)
                        .then(function(value) {
                          setError('');
                        })
                        .catch(function(err) {
                          setError(<FormattedMessage id="forgetpassword.email.format" />);
                        });
                    } else {
                      setError('');
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon color="action" />
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  className={classes.field}
                  id="passcode"
                  name="passcode"
                  type="passcode"
                  variant="outlined"
                  fullWidth
                  label={<FormattedMessage id="forgetpassword.passcode" />}
                  values={passcode}
                  error={passcodeError}
                  helperText={<span>{passcodeError}</span>}
                  onChange={(e) => {
                    setPasscode(e.target.value);
                    if (e.target.value != '') {
                      var passcode = {
                        passcode: e.target.value
                      }
                      yupObjectPassCode
                        .validate(passcode)
                        .then(function(value) {
                          setPasscodeError('');
                        })
                        .catch(function(err) {
                          setPasscodeError(<FormattedMessage id="forgetpassword.passcode.format" />);
                        });
                    } else {
                      setPasscodeError('');
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PasscodeIcon color="action" />
                      </InputAdornment>
                    )
                  }}
                />
                <br></br>
                <TextField
                  className={classes.field}
                  id="new password"
                  name="new password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  label={<FormattedMessage id="forgetpassword.newpassword" />}
                  values={password}
                  error={passwordError}
                  helperText={<span>{passwordError}</span>}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value != '') {
                      var password = {
                        password: e.target.value
                      }
                      yupObjectPassword
                        .validate(password)
                        .then(function(value) {
                          setPasswordError('');
                        })
                        .catch(function(err) {
                          setPasswordError(<FormattedMessage id="forgetpassword.password.invalid" />);
                        });
                    } else {
                      setPasswordError('');
                    }
                    if (e.target.value == confirmedPassword)
                      setConfirmedPasswordError('');
                    else
                      setConfirmedPasswordError(<FormattedMessage id="forgetpassword.confirmedPassword.invalid" />);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="action" />
                      </InputAdornment>
                    )
                  }}
                />
                <br></br>
                <TextField
                  className={classes.field}
                  id="confirmed new password"
                  name="confirmed new password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  label={<FormattedMessage id="forgetpassword.newpassword.reenter" />}
                  value={confirmedPassword}
                  error={confirmedPasswordError}
                  helperText={<span>{confirmedPasswordError}</span>}
                  onChange={(e) => {
                    setConfirmedPassword(e.target.value);
                    if (password != '') {
                      if (password != e.target.value)
                        setConfirmedPasswordError(<FormattedMessage id="forgetpassword.confirmedPassword.invalid" />);
                      else
                        setConfirmedPasswordError('');
                    } else {
                      setConfirmedPasswordError('');
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="action" />
                      </InputAdornment>
                    )
                  }}
                />

                <br></br>

                <Box position="relative">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleNewPasswordSubmit}
                  disabled={isSubmitting || passcodeError != '' || inputEmail == '' || passwordError != '' || confirmedPasswordError != ''
                    || passcode == '' || password == '' || confirmedPassword == ''}
                >
                  {!isSubmitting ? (
                    <FormattedMessage id="forgetpassword.button.send" />
                  ) : (
                      <FormattedMessage id="forgetpassword.button.send.loading" />
                    )}
                </Button>
              </Box>

                <Grid container>
                  <Grid item xs>
                    <Link href={LOGIN_PATH} variant="body1">
                      <FormattedMessage id="forgetpassword.back.login.page" />
                    </Link>
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    className={classes.locale}
                  >
                    <Grid item>
                      <LocaleLink to="en" current={locale} handleChange={setLocale}>
                        English
                  </LocaleLink>
                    </Grid>
                    <Grid item>
                      <LocaleLink
                        to="zh-HK"
                        current={locale}
                        handleChange={setLocale}
                      >
                        繁體中文
                  </LocaleLink>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Copyright />
                  </Grid>
                </Grid>
              </div>
            </Grid>

          )}
      </Grid>
    </IntlProvider>
  );
}

export const yup = require("yup");
export const yupObjectVerify = yup.object().shape({
  email: yup.string().email().required()
});

export const yupObjectPassCode = yup.object().shape({
  passcode: yup.number().required()
});

export const yupObjectPassword = yup.object().shape({
  password: yup.string().required().min(6)
});