import React, { useCallback } from "react";
import axios from "axios";
import FormikLoadableSelect from "./FormikLoadableSelect";
import { retrieveInstructorName } from "../../utils/app.util";

const FormikInstructorSelect = props => {
  const request = useCallback(() => {
    return axios.get("/api/staffUsers/variants/references", {
      params: { instructor: true, unPaged: true }
    });
  }, []);

  return (
    <FormikLoadableSelect
      getOptionLabel={option => retrieveInstructorName(option)}
      {...props}
      request={request}
    />
  );
};

export default FormikInstructorSelect;
