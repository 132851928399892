import React, { useState } from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import { retrieveInstructorName } from "../../../utils/app.util";
import { Formik, useFormikContext } from "formik";
import getAllFieldErrors from "../../../utils/api.util";
import moment from "moment";

const RemoveBatchConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity, schedule, scheduleRemove, sessionList, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  const [loading, setLoading] = React.useState(false);

  const handleBatchRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

   setLoading(true);

    var filterSession = [];
      for(var i = 0 ; i < sessionList.sessionList.length; i++){
          if((moment(sessionList.sessionList[i].scheduledDay, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleRemove.endDate, 'YYYY-MM-DD')) && 
              moment(sessionList.sessionList[i].scheduledDay, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleRemove.startDate, 'YYYY-MM-DD'))) ) {
                  filterSession.push(sessionList.sessionList[i]);
              }
      }

      for (var i = 0; i < filterSession.length; i++) {
        axios
        .put(`/api/offeringScheduleSessions/${filterSession[i].id}`, createParamsCancel(schedule, filterSession[i]))
        .then(() => {

        })
        .catch(error => {
          if (error.response.status === 400) {

          }

          notifyApiError(
            error.response.status,
            {
              400: {
                message: `Invalid inputs found for session `,
                variant: "warning"
              },
              403: {
                message: `Access denied to update sessions`,
                variant: "error"
              }
            },
            {
              message: `Unable to update session `,
              variant: "error"
            }
          );
        })
      }

      notify(
          `Sessions from "${scheduleRemove.startDate}" to "${scheduleRemove.endDate}" are deleted`,
          "success"
        );
     
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
        open={open}
        onClose={onClose}
        title="Remove Class Schedule?"
        message={
            <React.Fragment>
            Sessions from <b>{scheduleRemove.startDate}</b> to <b>{scheduleRemove.endDate}</b> are going to be deleted. You will not be able to recover the data once removed.
            </React.Fragment>
        }
        onConfirm={handleBatchRemove}
    />
  );
};

export default RemoveBatchConfirmationDialog;

  export const createParamsCancel = (schedule, filterSession) => {
    const params = new URLSearchParams();

    schedule.instructor && params.append("instructor", schedule.instructorId);
    params.append("day", filterSession.scheduledDay);
    schedule.startTime && params.append("startTime", schedule.startTime);
    schedule.endTime && params.append("endTime", schedule.endTime);
  
    params.append("status", 'CANCELLED');
  
    return params;
  };

