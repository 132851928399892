import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import { makeStyles } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import moment from "moment";

const useStyles = makeStyles({
  form: {
    width: 400
  }
});

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      paperClassName={classes.form}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <FormikKeyboardDatePicker name="day" label="Closed Business Day" />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  day: Yup.object()
    .nullable()
    .required("Required")
});

export const initialValues = {
  id: null,
  day: ""
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.day && params.append("day", moment(values.day).format("YYYY-MM-DD"));

  return params;
};
