import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(1),
    ...theme.typography.h5
  }
}));

export default function(props) {
  const { name, label, description, FormLabelProps, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    isSubmitting
  } = useFormikContext();

  const error = Boolean(touched[name] && errors[name]);

  return (
    <FormControl {...rest} error={error} disabled={isSubmitting}>
      <FormLabel
        component="legend"
        {...FormLabelProps}
        className={clsx(
          classes.label,
          FormLabelProps && FormLabelProps.className
        )}
      >
        {label}
      </FormLabel>
      <Typography variant="body1" color="textSecondary">
        {description}
      </Typography>
      <Switch
        edge="start"
        color="secondary"
        {...rest}
        name={name}
        checked={values[name]}
        onChange={event => {
          setFieldTouched(name, true, false);
          setFieldValue(name, event.target.checked);
        }}
      />
    </FormControl>
  );
}
