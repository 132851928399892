import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  subTitle: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "nowrap"
  },
  subTitleIcon: {
    marginRight: theme.spacing(0.5)
  },
  subTitleText: {
    lineHeight: "24px",
    marginRight: theme.spacing(2)
  }
}));

const IconSubTitle = props => {
  const { config } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {config.map(item => {
        const IconComponent = item.icon;

        return (
          <div className={classes.subTitle} key={item.description}>
            <IconComponent className={classes.subTitleIcon} />
            <Typography
              component="span"
              variant="subtitle2"
              className={classes.subTitleText}
              noWrap
            >
              {item.description}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default IconSubTitle;
