import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/promotions/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch promotion "${entity.title}"`,
                variant: "error"
              }
            },
            {
              message: `Unable to fetch promotion "${entity.title}"`,
              variant: "error"
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog {...rest} open={open} onClose={onClose} loading={loading} title="Promotion Information">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Promotion Image</TableCell>
            <TableCell>{detailedEntity.imageUrl && <img src={detailedEntity.imageUrl} />}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>{detailedEntity.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{detailedEntity.description}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Display Status</TableCell>
            <TableCell>{detailedEntity.displayStatus ? "Displaying" : "Not Displayed"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>{detailedEntity.startDate && moment(detailedEntity.startDate).format("YYYY-MM-DD")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>End Date</TableCell>
            <TableCell>{detailedEntity.endDate && moment(detailedEntity.endDate).format("YYYY-MM-DD")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Start Time</TableCell>
            <TableCell>{detailedEntity.startTime && moment(detailedEntity.startTime, "HH:mm").format("HH:mm")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>End Time</TableCell>
            <TableCell>{detailedEntity.endTime && moment(detailedEntity.endTime, "HH:mm").format("HH:mm")}</TableCell>
          </TableRow>
          {detailedEntity.promotionBranches && detailedEntity.promotionBranches.length > 0 && (
            <TableRow>
              <TableCell>Store Branch</TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    {detailedEntity.promotionBranches.map(promotionBranch => (
                      <TableRow>
                        <TableCell>{promotionBranch.applicableStoreBranch.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
