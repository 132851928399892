import React from "react";

const Error404Icon = props => (
  <svg viewBox="0 0 511 511" {...props}>
    <path d="M39.5 103c1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3a7.51 7.51 0 00-7.5-7.5c-1.97 0-3.91.8-5.3 2.2-1.4 1.39-2.2 3.33-2.2 5.3s.8 3.91 2.2 5.3a7.51 7.51 0 005.3 2.2zM63.5 103c1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3s-.8-3.91-2.2-5.3a7.528 7.528 0 00-5.3-2.2c-1.97 0-3.91.8-5.3 2.2-1.4 1.39-2.2 3.33-2.2 5.3s.8 3.91 2.2 5.3c1.39 1.4 3.33 2.2 5.3 2.2zM87.5 103c1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3s-.8-3.91-2.2-5.3a7.528 7.528 0 00-5.3-2.2c-1.97 0-3.91.8-5.3 2.2-1.4 1.39-2.2 3.33-2.2 5.3s.8 3.91 2.2 5.3c1.39 1.4 3.33 2.2 5.3 2.2zM119.5 103h304a7.5 7.5 0 000-15h-304a7.5 7.5 0 000 15zM455.5 103h16a7.5 7.5 0 000-15h-16a7.5 7.5 0 000 15zM263.5 224h-16c-17.369 0-31.5 14.131-31.5 31.5v48c0 17.369 14.131 31.5 31.5 31.5h16c17.369 0 31.5-14.131 31.5-31.5v-48c0-17.369-14.131-31.5-31.5-31.5zm16.5 79.5c0 9.098-7.402 16.5-16.5 16.5h-16c-9.098 0-16.5-7.402-16.5-16.5v-48c0-9.098 7.402-16.5 16.5-16.5h16c9.098 0 16.5 7.402 16.5 16.5v48zM199.5 296H191v-64.5a7.5 7.5 0 00-15 0V296h-38.094l20.709-62.128a7.5 7.5 0 00-14.231-4.744l-24 72A7.5 7.5 0 00127.5 311H176v16.5a7.5 7.5 0 0015 0V311h8.5a7.5 7.5 0 000-15zM383.5 296H375v-64.5a7.5 7.5 0 00-15 0V296h-38.094l20.709-62.128a7.5 7.5 0 00-14.231-4.744l-24 72A7.5 7.5 0 00311.5 311H360v16.5a7.5 7.5 0 0015 0V311h8.5a7.5 7.5 0 000-15z" />
    <path d="M471.5 56h-432C17.72 56 0 73.72 0 95.5v320C0 437.28 17.72 455 39.5 455h432c21.78 0 39.5-17.72 39.5-39.5v-320c0-21.78-17.72-39.5-39.5-39.5zm-432 15h432c13.509 0 24.5 10.991 24.5 24.5V120H15V95.5C15 81.991 25.991 71 39.5 71zm432 369h-432C25.991 440 15 429.009 15 415.5V135h481v280.5c0 13.509-10.991 24.5-24.5 24.5z" />
  </svg>
);

export default Error404Icon;
