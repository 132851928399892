import Link from "@material-ui/core/Link";
import React from "react";
import { isSameLanguage } from "../../../utils/locale.util";

export default function LocaleLink(props) {
  const { handleChange, to, current } = props;

  let isSame = isSameLanguage(current, to);

  return (
    <Link
      variant="body2"
      component={isSame ? "span" : "button"}
      color={isSame ? "textSecondary" : undefined}
      underline={isSame ? "none" : "always"}
      onClick={
        isSame
          ? undefined
          : () => {
              handleChange(to);
            }
      }
    >
      {props.children}
    </Link>
  );
}
