import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  form: {
    width: 400
  }
});

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      paperClassName={classes.form}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <FormikTextField
            autoFocus
            required
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            margin="dense"
          />
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(150, "Name cannot be longer than 150.")
    .required("Required")
});

export const initialValues = {
  id: null,
  name: ""
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.name && params.append("name", values.name);

  return params;
};
