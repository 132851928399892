import React, { useCallback } from "react";
import axios from "axios";
import FormikLoadableSelect from "./FormikLoadableSelect";

const FormikOfferingCategorySelectForPackagePlan = props => {
  const request = useCallback(() => {
    return axios.get("/api/offeringCategories/variants/references/packageplan");
  }, []);

  return <FormikLoadableSelect getOptionLabel={option => (option && option.name ? option.name : "")} {...props} request={request} />;
};

export default FormikOfferingCategorySelectForPackagePlan;
