import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import ErrorFilterResultPlaceholder from "./filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "./filters/EmptyFilterResultPlaceholder";
import Frame from "./Frame";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  table: {
    marginTop: theme.spacing(3)
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const EntityManagement = props => {
  const {
    pageHeader: PageHeader,
    filterForm: FilterForm,
    filterFormInitialValues,
    createFormDialog: CreateFormDialog,
    editPackageFormDialog: EditPackageFormDialog,
    editFormDialog: EditFormDialog,
    displayDialog: DisplayDialog,
    removeConfirmationDialog: RemoveConfirmationDialog,
    emailDialog: EmailDialog,
    listingTable: ListingTable,
    latestPackageDialog: LatestPackageDialog,
    onFilter,
    api,
    request,
    parent,
    initialPageSize,
    onLoadPageHeader
  } = props;

  const classes = useStyles();

  /*
  Status for loading listing table
   */

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableError, setLoadingTableError] = useState(false);
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false);

  /*
  Filter
   */

  const [filters, setFilters] = React.useState(
    filterFormInitialValues ? (onFilter ? onFilter(filterFormInitialValues) : filterFormInitialValues) : {}
  );

  const [filtersId, setFiltersId] = React.useState(null);

  const handleFilterSubmit = values => {
    setShowTableWhileLoading(false);
    setPageNumber(0);
    if ('packagePlan' in values){
      if ('id' in values['packagePlan']){
        setFiltersId(values['packagePlan']['id']);
      }
    }
    setFilters(onFilter ? onFilter(values) : values);
  };

  const handleFilterReset = () => {
    setShowTableWhileLoading(false);
    setPageNumber(0);
    setFiltersId(null);
    setFilters({});
  };

  /*
  Form dialog for create
   */

  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(false);

  const handleCreateFormDialogOpen = React.useCallback(() => {
    setCreateFormDialogOpen(true);
  }, []);

  const handleCreateFormDialogClose = React.useCallback(() => {
    setCreateFormDialogOpen(false);
  }, []);

  const createFormDialog = CreateFormDialog && (
    <CreateFormDialog
      open={createFormDialogOpen}
      onOpen={handleCreateFormDialogOpen}
      onClose={handleCreateFormDialogClose}
      onSuccess={() => {
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      onEnroll={() => {
        onLoadPageHeader(true);
      }}
      parent={parent}
    />
  );

  /*
  Form dialog for edit package in OfferingScheduleSessionTranscriptManagement.js
   */

  const [editPackageFormDialogState, setEditPackageFormDialogState] = useState({
    open: false
  });

  const handleEditPackageFormDialogOpen = React.useCallback(() => {
    setEditPackageFormDialogState(prevState => ({
      ...prevState,
      open: true
    }));
  }, []);

  const handleEditPackageFormDialogClose = React.useCallback(() => {
    setEditPackageFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const editPackageFormDialog = EditPackageFormDialog && (
    <EditPackageFormDialog
      {...editPackageFormDialogState}
      onOpen={handleEditPackageFormDialogOpen}
      onClose={handleEditPackageFormDialogClose}
      onSuccess={() => {
        setEditPackageFormDialogState(prevState => ({
          ...prevState,
          entity: null
        }));

        setShowTableWhileLoading(true);
        setFilters({ ...filters });
        onLoadPageHeader(true);
      }}
      parent={parent}
    />
  );

  /*
  Form dialog for edit
   */

  const [editFormDialogState, setEditFormDialogState] = useState({
    open: false
  });

  const handleEditFormDialogOpen = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: true
    }));
  }, []);

  const handleEditFormDialogClose = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const editFormDialog = EditFormDialog && (
    <EditFormDialog
      {...editFormDialogState}
      onOpen={handleEditFormDialogOpen}
      onClose={handleEditFormDialogClose}
      onSuccess={() => {
        setEditFormDialogState(prevState => ({
          ...prevState,
          entity: null
        }));

        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      onBefore={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                updating: true
              };
            }

            return entity;
          })
        );
      }}
      onAfter={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                updating: undefined
              };
            }

            return entity;
          })
        );
      }}
      parent={parent}
    />
  );

  /*
  Dialog for display information
   */

  const [displayDialogState, setDisplayDialogState] = React.useState({
    open: false
  });

  const handleDisplayDialogClose = React.useCallback(() => {
    setDisplayDialogState(prevState => ({
      ...prevState,
      open: false,
      entity: null
    }));
  }, []);

  const displayDialog = DisplayDialog && <DisplayDialog {...displayDialogState} onClose={handleDisplayDialogClose} />;

  /*
  Dialog for remove confirmation
   */

  const [removeConfirmationDialogState, setRemoveConfirmationDialogState] = React.useState({
    open: false
  });

  const handleRemoveConfirmationDialogClose = React.useCallback(() => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const removeConfirmationDialog = RemoveConfirmationDialog && (
    <RemoveConfirmationDialog
      {...removeConfirmationDialogState}
      onClose={handleRemoveConfirmationDialogClose}
      onSuccess={() => {
        setShowTableWhileLoading(true);

        if (entities.length === 1 && pageNumber > 0) {
          setPageNumber(pageNumber - 1);
        } else {
          setFilters({ ...filters });
        }
        onLoadPageHeader(true);
      }}
      onBefore={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                deleting: true
              };
            }

            return entity;
          })
        );
      }}
      onAfter={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                deleting: undefined
              };
            }

            return entity;
          })
        );
      }}
      onCancel={() => {
        onLoadPageHeader(true);
      }}
      parent={parent}
    />
  );

  /*
  Dialog for email
  */

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  const handleEmailDialogOpen = React.useCallback(() => {
    setEmailDialogOpen(true);
  }, []);

  const handleEmailDialogClose = React.useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const emailDialog = EmailDialog && (
    <EmailDialog
      open={emailDialogOpen}
      onOpen={handleEmailDialogOpen}
      onClose={handleEmailDialogClose}
      onSuccess={() => {
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      parent={parent}
    />
  );

  /*
  Table entities
   */

  const [entities, setEntities] = useState([]);

  /*
  Dialog for LatestPackage
  */
  const [latestPackageDialogState, setLatestPackageDialogState] = React.useState({
    open: false
  });

  const handleLatestPackageDialogClose = React.useCallback(() => {
    setLatestPackageDialogState(prevState => ({
      ...prevState,
      open: false,
      entity: null
    }));
  }, []);

  const handleLatestPackageDialogOpen = entity => {
    setLatestPackageDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const latestPackageDialog = LatestPackageDialog && (
    <LatestPackageDialog {...latestPackageDialogState} entity={latestPackageDialogState.entity} onClose={handleLatestPackageDialogClose} />
  );

  /*
  Handle CRUD actions
   */

  const handleView = entity => {
    setDisplayDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleCreate = () => {
    setCreateFormDialogOpen(true);
  };

  const handleEditPackage = entity => {
    setEditPackageFormDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleEdit = entity => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleRemove = entity => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));

  };

  /*
   Listing table pagination
   */

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize || 10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageNumberChange = (event, page) => {
    setPageNumber(page);
    setShowTableWhileLoading(true);
  };

  const handlePageSizeChange = event => {
    setPageSize(event.target.value);
    setPageNumber(0);
    setShowTableWhileLoading(true);
  };

  const handleRefreshListingTable = event => {
    setShowTableWhileLoading(true);
    setFilters({ ...filters });
  };

  /*
  Listing table
   */
  const listingTable = (
    <ListingTable
      className={classes.table}
      entities={entities}
      onView={handleView}
      onEdit={handleEdit}
      onEditPackage={handleEditPackage}
      onEditPackageSuccess={handleRefreshListingTable}
      onViewLatestPackage={handleLatestPackageDialogOpen}
      onDelete={handleRemove}
      totalCount={totalCount}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      loading={loadingTable}
      parent={parent}
    />
  );

  /*
  Load entities
   */

  useLayoutEffect(() => {
    let active = true;

    setLoadingTable(true);
    setLoadingTableError(false);

    let params = {
      ...filters,
      filtersId: filtersId,
      page: pageNumber,
      size: pageSize
    };
    
    if (filtersId != null){
      params = {
        filtersId: filtersId,
        page: pageNumber,
        size: pageSize
      }
    }
    

    const result = request ? request(params) : axios.get(api, { params: params });

    let entityStatusFilter = false;
    if (request && request.toString().includes('/api/transcripts/resources') && request.toString().includes('offeringScheduleSession'))
      entityStatusFilter = true;

    result
      .then(response => {
        if (entityStatusFilter) {
          if (response.data["content"]) {
            var entities = response.data["content"].filter(entity => true || (entity.status !== "LATE_CANCELLED" && entity.status !== "CANCELLED")) || [];
            var filterCount = 0;
            response.data["content"].map(entity => {if (entity.status === "LATE_CANCELLED" || entity.status === "CANCELLED") filterCount++;});
            active && setEntities(entities);
            active && setTotalCount(response.data["totalElements"] - filterCount);
          }
          else {
            active && setEntities([]);
            active && setTotalCount(0);
          }
        } else {
          active && setEntities(response.data["content"] || []);
          active && setTotalCount(response.data["totalElements"]);
        }
        active && setLoadingTableError(false);
      })
      .catch(() => {
        active && setLoadingTableError(true);
      })
      .finally(() => {
        active && setLoadingTable(false);
      });

    return () => {
      active = false;
    };
  }, [api, filters, filtersId, pageNumber, pageSize, request]);

  return (
    <Frame className={classes.root}>
      <PageHeader onCreate={handleCreate} onEmailDialog={() => setEmailDialogOpen(true)} parent={parent} />
      {FilterForm && <FilterForm initialValues={filterFormInitialValues} loading={loadingTable} onSubmit={handleFilterSubmit} onReset={handleFilterReset} parent={parent} />}
      {loadingTableError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : loadingTable ? (
        showTableWhileLoading ? (
          listingTable
        ) : (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )
      ) : entities && entities.length > 0 ? (
        listingTable
      ) : (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      )}
      {createFormDialog}
      {editFormDialog}
      {editPackageFormDialog}
      {displayDialog}
      {removeConfirmationDialog}
      {emailDialog}
      {latestPackageDialog}
    </Frame>
  );
};

export default EntityManagement;
