import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import localeService from "../../services/locale.service";
import { IntlProvider } from "react-intl";
import { MESSAGES } from "../../constants/locale.constant";
import { PRIVACY_EN_PATH } from "../../constants/route.constant";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%"
  },
  versionDiv: {
    float: "right"
  }
}));

export default function PrivacyZhPage() {
  const classes = useStyles();

  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  return (
    <IntlProvider locale={locale} messages={MESSAGES[locale]}>
      <Grid
        component="main"
        justify="space-evenly"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.panel}>

          <div  className={classes.versionDiv}>
            <Link to={PRIVACY_EN_PATH}>
              English Version
            </Link>
          </div>

          <h2><b>隱私</b></h2>
            <pre>
{`
免責條款
本網頁所載全部資料僅供參考。Webz Design Company不保證或聲明本網頁所提供的資料屬準確、完整或最新，並表示既不會就因本網頁的內容而產生及因依賴本網頁內容而引致的任何損失而承擔任何責任。


個人資料收集聲明
我們致力保護你的個人資料(“個人資料”)。此個人資料收集聲明是依從香港特別行政區之《個人資料(私隱)條例》之要求，於向你收集個人資料時或之前通知你若干事項。我們所收集及使用的個人資料只限用於我們業務所需及向你提供最高服務質素的範圍內。

 
我們可使用個人資料於下列一項或多項的用途：
1. 由我們或合作夥伴所提供有關設施及/或服務；

2. 資料統計、通訊、市場營銷、推廣、客戶關係管理、公共關係及/或直接促銷；

3. 客戶、會員或使用者帳戶及記錄的申請、成立、運作及行政管理；

4. 處理意見、查詢及投訴；

5. 處理帳單及付款；

6. 識別及核實身份；

7. 管理及維護網站運作，及於有需要時診斷問題；

8. 載於收集你的個人資料時所提供的個人資料收集聲明中的任何其他目的，或你可能不時同意的任何其他目的。

我們可能提供及/或分享個人資料予我們的第三方服務供應商、代理及承包商為我們作上述用途。我們亦可能基於以下情況透露個人資料：法律所需，法庭命令，政府或執法機構所需或懇切相信因保障我們或其他人仕的權利或資產而有必要或被建議透露個人資料。

我們擬使用個人資料以直接促銷指定產品及服務，但我們在未得到你的同意之前，不可如此使用個人資料。就此，我們可能使用以下個人資料：

(a) 名稱及聯絡資料；

(b) 產品及服務資訊；

(c) 交易模式及行為；及

(d) 統計資料。

個人資料的提供完全是自願性質。然而，如你選擇不向我們提供個人資料，則我們可能無法為你提供有關的資訊或服務，或處理你的要求。

我們會徵求你的同意使用個人資料作上述直接促銷用途。請你於提供個人資料前，表示你同意或反對我們如此使用個人資料作上述直接促銷用途。你亦可於之後的任何時候電郵至webzdesigncompany@gmail.com，向我們的客戶服務主任提出要求我們停止使用個人資料作直接促銷用途或上述任何其他用途。

如要提出查閱或更正個人資料的要求，或查詢有關我們私隱政策，可向我們的客戶服務主任提出：webzdesigncompany@gmail.com。

中英文本如有歧異，一概以英文本為準。，向我們的客戶服務主任提出要求我們停止使用個人資料作直接促銷用途或上述任何其他用途。
`}
            </pre>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
