import React from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikImageDropzone from "../../../components/inputs/FormikImageDropzone";
import FormikOfferingCategorySelect from "../../../components/inputs/FormikOfferingCategorySelect";
import FormikTextArea from "../../../components/inputs/FormikTextArea";

const useStyles = makeStyles(theme => ({
  form: {
    width: 800
  },
  editorLabel: {
    ...theme.typography.h5
  }
}));

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
      paperClassName={classes.form}
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormikImageDropzone name="imageUrl" policy="OFFERING_IMAGE"/>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <FormikTextField
                required
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormikOfferingCategorySelect
                name="offeringCategory"
                label="Class Category"
                margin="dense"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormikTextArea
            fullWidth
            name="description"
            label="Description"
            variant="outlined"
            rows="8"
          />
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(200, "No more than 200 characters long")
    .required("Required")
});

export const initialValues = {
  id: null,
  name: "",
  description: "",
  offeringCategory: null,
  imageUrl: null,
  files: []
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.name && params.append("name", values.name);
  values.description && params.append("description", values.description);

  if (values.offeringCategory && values.offeringCategory.id) {
    params.append("offeringCategory", values.offeringCategory.id);
  }

  if (values.imageUrl) {
    params.append("imageUrl", values.imageUrl);
  }

  return params;
};
