import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import {PROGRESS_TIME_LIMIT} from "../../../config/app.config";
import axios from "axios";
import moment from "moment";

export default function (props) {
  const {open, onClose, onBefore, onAfter, onSuccess, entity} = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleRemove = () => {
    if (!entity) {
      return;
    }
    
    onClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing session "${moment(entity.day).format("YYYY-MM-DD")}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .delete(`/api/offeringScheduleSessions/${entity.id}`)
      .then(() => {
        notify(`Session "${moment(entity.day).format("YYYY-MM-DD")}" removed`, "success");

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to remove session "${moment(entity.day).format("YYYY-MM-DD")}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to remove session "${moment(entity.day).format("YYYY-MM-DD")}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Session?"
      message={
        <React.Fragment>
          Session <b>{moment(entity.day).format("YYYY-MM-DD")}</b> is going to be deleted. You will not
          be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
}
