import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { RECURRING_TYPE_VALUE_NAME_MAPPING } from "../../../constants/app.constant";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import { retrieveInstructorName } from "../../../utils/app.util";
import { recurringDaysAsString } from "../utils/schedule.util";
import moment from "moment";

const DisplayDialog = props => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    const instructorName = retrieveInstructorName(entity.instructor);

    axios
      .get(`/api/offeringSchedules/${entity.id}`)
      .then(response => {
        active && setDetailedEntity(response.data);
      })
      .catch(error => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch class schedule for instructor "${instructorName}"`,
                variant: "error"
              }
            },
            {
              message: `Unable to fetch class schedule for instructor "${instructorName}"`,
              variant: "error"
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Class Schedule Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Instructor</TableCell>
            <TableCell>
              {detailedEntity.instructor &&
                retrieveInstructorName(detailedEntity.instructor)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recurring Pattern</TableCell>
            <TableCell>
              {detailedEntity.recurringType &&
                RECURRING_TYPE_VALUE_NAME_MAPPING[detailedEntity.recurringType]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recurring Days</TableCell>
            <TableCell>
              {detailedEntity.recurringOrders &&
                recurringDaysAsString(
                  detailedEntity.recurringType,
                  detailedEntity.recurringOrders.sort((a, b) => a - b)
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>
              {detailedEntity.startDate &&
                moment(detailedEntity.startDate).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>End Date</TableCell>
            <TableCell>
              {detailedEntity.endDate &&
                moment(detailedEntity.endDate).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Start Time</TableCell>
            <TableCell>
              {detailedEntity.startTime &&
                moment(detailedEntity.startTime, "HH:mm").format("HH:mm")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>End Time</TableCell>
            <TableCell>
              {detailedEntity.endTime &&
                moment(detailedEntity.endTime, "HH:mm").format("HH:mm")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Capacity</TableCell>
            <TableCell>{detailedEntity.capacity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Online Capacity</TableCell>
            <TableCell>{detailedEntity.onlineCapacity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Wait List Capacity</TableCell>
            <TableCell>{detailedEntity.waitListCapacity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Store Branch</TableCell>
            <TableCell>
              {detailedEntity.storeBranch && detailedEntity.storeBranch.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Show to Public</TableCell>
            <TableCell>
              {detailedEntity.publicAvailable ? "Yes" : "No"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;
