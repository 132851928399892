import { ACCESS_TOKEN_KEY } from "../constants/security.constant";
import { sessionStatusInitial } from "../security/SecurityFilter/actions/sessionStatus.action";
import { removePersonalSettings } from "../security/SecurityFilter/actions/personalSettings.action";

class securityService {
  setAccessToken = accessToken => {
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    } else {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
    }
  };

  removeAccessToken = () => {
    this.setAccessToken(null);
  };

  getAccessToken = () => {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
  };

  hasAccessToken = () => {
    return !!this.getAccessToken();
  };

  logout = dispatch => {
    this.removeAccessToken();
    dispatch(removePersonalSettings());
    dispatch(sessionStatusInitial());
  };
}

export default new securityService();
