import React from "react";
import { Formik } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validationSchema
} from "./AttributeFormDialog";
import moment from "moment";

const CreateForm = props => {
  const { open, onClose, onEnroll, ...rest } = props;

  return (
    <AttributeFormDialog
      {...rest}
      title="Add Closed Business Day"
      open={open}
      onClose={onClose}
      loading={false}
    />
  );
};

const FormikCreateForm = props => {
  const { onOpen, onClose, onSuccess, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const formattedDay = moment(values.day).format("YYYY-MM-DD");

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Adding closed business day "${formattedDay}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .post("/api/closedBusinessDays", createParams(values))
      .then(() => {
        notify(`Closed business day "${formattedDay}" added`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for closed business day "${formattedDay}"`,
              variant: "warning"
            },
            403: {
              message: `Access denied to add closed business day`,
              variant: "error"
            }
          },
          {
            message: `Unable to add closed business day "${formattedDay}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <CreateForm {...rest} onClose={onClose} />
    </Formik>
  );
};

export default FormikCreateForm;
