import React from "react";
import {KeyboardTimePicker} from "@material-ui/pickers";
import {useFormikContext} from "formik";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import AutoCompleteOffTextField from "./AutoCompleteOffTextField";

export default function(props) {
  const { name, ...rest } = props;

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting
  } = useFormikContext();

  return (
    <KeyboardTimePicker
      autoOk
      ampm={false}
      inputVariant="outlined"
      format="HH:mm"
      placeholder="HH:mm"
      margin="dense"
      fullWidth
      InputAdornmentProps={{ position: "start" }}
      KeyboardButtonProps={{ size: "small" }}
      keyboardIcon={<AccessTimeOutlinedIcon />}
      TextFieldComponent={AutoCompleteOffTextField}
      {...rest}
      name={name}
      value={values[name]}
      onChange={date => {
        setFieldValue(name, date);
      }}
      onClose={() => {
        setFieldTouched(name, true, false);
      }}
      onBlur={handleBlur}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      onError={error => {
        if (error && errors[name] !== error) {
          setFieldError(name, error);
        }
      }}
      disabled={isSubmitting}
    />
  );
}
