import { useFormikContext } from "formik";
import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  actionsAlignLeft: {
    justifyContent: "flex-start"
  },
  searchButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0),
    height: theme.spacing(5)
  },
  searchButtonAlignLeft: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(2)
  }
}));

export default function(props) {
  const { loading, align } = props;

  const classes = useStyles();

  const { resetForm, isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <div
      className={clsx(classes.actions, {
        [classes.actionsAlignLeft]: align === "left"
      })}>
      <Button
        className={clsx(classes.searchButton, {
          [classes.searchButtonAlignLeft]: align === "left"
        })}
        variant="contained"
        type="submit"
        color="secondary"
        disabled={isSubmitting || loading}>
        Search
      </Button>
      <Button
        className={clsx(classes.searchButton, {
          [classes.searchButtonAlignLeft]: align === "left"
        })}
        variant="contained"
        color="default"
        onClick={() => resetForm()}
        disabled={loading}>
        Reset
      </Button>
    </div>
  );
}
