import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    width: 1280,
    maxWidth: "100%",
    margin: "auto",
    padding: theme.spacing(3)
  }
}));

export default function Frame({
  component: Component,
  className,
  children,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      {children}
    </Component>
  );
}

Frame.defaultProps = {
  component: "div"
};
