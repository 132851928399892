import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import React from "react";
import FilterFormButtonGroup from "../../../components/filters/FilterFormButtonGroup";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  BOOLEAN_OPTIONS,
  OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS
} from "../../../constants/app.constant";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";

const FilterForm = props => {
  const { loading } = props;

  const { handleSubmit } = useFormikContext();

  return (
    <form onSubmit={handleSubmit}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <FormikAutocomplete
              name="includePast"
              margin="dense"
              autoHighlight
              options={BOOLEAN_OPTIONS}
              getOptionLabel={option => option && (option.label || "")}
              TextFieldProps={{
                label: "Include Past?",
                variant: "outlined",
                fullWidth: true
              }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <FormikKeyboardDatePicker name="dayAfter" label="Date After" />
          </Grid>
          <Grid item md={3} xs={6}>
            <FormikKeyboardDatePicker name="dayBefore" label="Date Before" />
          </Grid>
          <Grid item md={3} xs={6}>
            <FormikAutocomplete
              name="status"
              margin="dense"
              autoHighlight
              options={OFFERING_SCHEDULE_SESSION_STATUS_OPTIONS}
              getOptionLabel={option => option && (option.label || "")}
              renderOption={option => option && (option.display || "")}
              TextFieldProps={{
                label: "Status",
                variant: "outlined",
                fullWidth: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FilterFormButtonGroup loading={loading} />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </form>
  );
};

export default function(props) {
  const { initialValues, loading, onSubmit } = props;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <FilterForm loading={loading} />
    </Formik>
  );
}
